import { useState, useEffect } from "react";
import {
  IconButton,
  Grid,
  Box,
  Tabs,
  Tab,
  Link,
  FormControl,
  MenuItem,
  Chip,
  Select,
  ListItem,
} from "@mui/material";
import styles from "./index.module.scss";
import recordsApi from "../../services/enterprise/records";
import { useNavigate, useParams } from "react-router-dom";
import Icon from "@mdi/react";
import { mdiArrowLeft } from '@mdi/js';
import TabPanel from "../TabPanel";
import { useLocation } from 'react-router-dom';
import { useTimeUtils } from "../../hooks/timeUtils";
import classnames from "classnames";
import Pagination from "../Pagination"



const ToPercent = (num) => {
  if (typeof num !== 'number') return "";
  return Number(Math.round(num * 100).toFixed(2)) + "%";
};

// 調整日期格式
const ToDate = (date) => {
  const originalDate = new Date(date);
  const formattedDate = `${originalDate.getFullYear()}/${(originalDate.getMonth() + 1).toString().padStart(2, '0')}/${originalDate.getDate().toString().padStart(2, '0')}`;
  return formattedDate
}

const sprintStatus = (item, field) => {
  switch (field) {
    case "score":
      switch (item.sprintStatus) {
        case 0:
          return {
            text: "--",
            color: "#757575"
          };
        case 1:
          return {
            text: "進行中...",
            color: "#5BB6FF"
          };
        case 2:
          return {
            text: item.lastScore,
            color: "#757575"
          };
        case 3:
          return {
            text: item.lastScore > 0 ? item.lastScore : "--",
            color: "#D50000"
          };
        case 4:
          return {
            text: item.lastScore > 0 ? item.lastScore : "--",
            color: "#FF9900"
          };
        default:
          return null;
      }
    case "progress":
      switch (item.sprintStatus) {
        case 0:
          return {
            text: "尚未開始",
            color: "#757575"
          };
        case 1:
          return {
            text: "進行中...",
            color: "#5BB6FF"
          };
        case 2:
          return {
            text: `達成率 ${ToPercent(item.progress)}`,
            color: "#51CF66"
          }
        case 3:
          return {
            text: "過期",
            color: "#D50000"
          };
        case 4:
          return {
            text: "中止",
            color: "#FF9900"
          };
        default:
          return null;
      }
    default:
      return null;
  }
};

const UserSpringList = (props) => {
  const [orderBy, setOrderBy] = useState("spring_asc");
  const [displayList, setDisplayList] = useState([]);
  const { data } = props;
  // 目前所在頁數
  const [currentPage, setCurrentPage] = useState(1);
  // 每頁顯示項目數量
  const itmesPerPage = 20;
  // 總共需要的頁數 
  const totalPages = Math.ceil(displayList.length / itmesPerPage);
  // 計算該頁面最後 item 為第幾個。
  const indexOfLastItem = currentPage * itmesPerPage;
  // 計算該頁面開始 item 為第幾個。
  const indexOfFirstItem = indexOfLastItem - itmesPerPage;
  // 提取該頁面要顯示的資料
  const currentItems = displayList.slice(indexOfFirstItem, indexOfLastItem);

  const handlerOrderChange = (key) => {
    if (orderBy.indexOf(key) < 0 || orderBy.includes("asc"))
      setOrderBy(`${key}_desc`);
    else setOrderBy(`${key}_asc`);
  };

  const SortTH = ({ children, orderKey }) => {
    const [isMouseOver, setIsMouseOver] = useState(false);

    return (
      <th>
        <Link
          className={styles.header_action}
          underline="none"
          onClick={() => handlerOrderChange(orderKey)}
          onMouseOver={() => setIsMouseOver(true)}
          onMouseLeave={() => setIsMouseOver(false)}
          style={{ color: "#757575" }}
        >
          {children}&nbsp;
          <span
            className={classnames({
              [styles.hide_action]: orderBy.indexOf(`${orderKey}_`) < 0,
            })}
          >
            {orderBy.includes("asc") ? "▲" : "▼"}
          </span>
          <span
            className={classnames({
              [styles.mouseover_hide_action]:
                orderBy.indexOf(`${orderKey}_`) >= 0 || !isMouseOver,
            })}
          >
            ▼
          </span>
        </Link>
      </th>
    );
  };

  const handleCurrentPage = (page) => {
    setCurrentPage(page)
  }

  useEffect(() => {
    if (!data) return;

    const newData = [...data];

    switch (orderBy) {

      case "spring_asc":
        newData.sort((a, b) =>
          a.seque ? a.seque - b.seque : -1
        );
        break;
      case "spring_desc":
        newData.sort((a, b) =>
          b.seque ? b.seque - a.seque : -1
        );
        break;
      case "lastScore_asc":
        newData.sort((a, b) =>
          a.lastScore ? a.lastScore - b.lastScore : -1
        );
        break;
      case "lastScore_desc":
        newData.sort((a, b) =>
          b.lastScore ? b.lastScore - a.lastScore : -1
        );
        break;
      case "progress_asc":
        newData.sort((a, b) =>
          a.progress ? a.progress - b.progress : -1
        );
        break;
      case "progress_desc":
        newData.sort((a, b) =>
          b.progress ? b.progress - a.progress : -1
        );
        break;
      case "date_asc":
        newData.sort((a, b) =>
          a.startTime.localeCompare(b.startTime)
        );
        break;
      case "date_desc":
        newData.sort((a, b) =>
          b.startTime.localeCompare(a.startTime)
        );
        break;
    }
    setDisplayList(newData);
  }, [data, orderBy]);

  return (
    <div>
      <table className={styles.table} cellSpacing={0}>
        <thead >
          <tr>
            <SortTH orderKey="spring">計畫排序</SortTH>
            <SortTH orderKey="lastScore">當次預測分數</SortTH>
            <SortTH orderKey="progress">達成率</SortTH>
            <SortTH orderKey="date">日期</SortTH>
          </tr>
        </thead>
        <tbody>
          {displayList.length > 0 ? (
            currentItems.map((item, index) => {
              return (
                <tr key={`tr_${index}`}>
                  <td data-label={"計畫排序:"}>{`第 ${item.seque} 次訓練課表`}</td>
                  <td
                    data-label={"當次預測分數:"}
                    style={{ color: sprintStatus(item, "score").color }}
                  >
                    {sprintStatus(item, "score").text}
                  </td>
                  <td
                    data-label={"達成率:"}
                    style={{ color: sprintStatus(item, "progress").color }}
                  >
                    {sprintStatus(item, "progress").text}
                  </td>
                  <td data-label={"日期:"}>{ToDate(item.startTime)} ~ {ToDate(item.endTime)}</td>
                </tr>
              );
            })
          )
            : (
              <tr>
                <td colSpan={4} className={styles.emptyDataTable} >尚無訓練課表</td>
              </tr>
            )}
        </tbody>
      </table>
      <Pagination totalPages={totalPages} currentPage={handleCurrentPage} dataLength={displayList.length} />
    </div>
  )
}

const UserKnowledgePointList = (props) => {
  const [orderBy, setOrderBy] = useState("name_desc");
  const [filterData, setFilterData] = useState([]);
  const [displayList, setDisplayList] = useState([]);
  const [selectKnowledgeType, setSelectKnowledgeType] = useState(null);
  const [knowledgeTypeList, setKnowledgeTypeList] = useState([]);
  const { data } = props;
  const { toHHMMSS } = useTimeUtils();
  // 目前所在頁數
  const [currentPage, setCurrentPage] = useState(1);
  // 每頁顯示項目數量
  const itmesPerPage = 20;
  // 總共需要的頁數 
  const totalPages = Math.ceil(displayList.length / itmesPerPage);
  // 計算該頁面最後 item 為第幾個。
  const indexOfLastItem = currentPage * itmesPerPage;
  // 計算該頁面開始 item 為第幾個。
  const indexOfFirstItem = indexOfLastItem - itmesPerPage;
  // 提取該頁面要顯示的資料
  const currentItems = displayList.slice(indexOfFirstItem, indexOfLastItem);


  const handlerOrderChange = (key) => {
    if (orderBy.indexOf(key) < 0 || orderBy.includes("asc"))
      setOrderBy(`${key}_desc`);
    else setOrderBy(`${key}_asc`);
  };

  const SortTH = ({ children, orderKey }) => {
    const [isMouseOver, setIsMouseOver] = useState(false);

    return (
      <th>
        <Link
          className={styles.header_action}
          underline="none"
          onClick={() => handlerOrderChange(orderKey)}
          onMouseOver={() => setIsMouseOver(true)}
          onMouseLeave={() => setIsMouseOver(false)}
          style={{ color: "#757575" }}
        >
          {children}&nbsp;
          <span
            className={classnames({
              [styles.hide_action]: orderBy.indexOf(`${orderKey}_`) < 0,
            })}
          >
            {orderBy.includes("asc") ? "▲" : "▼"}
          </span>
          <span
            className={classnames({
              [styles.mouseover_hide_action]:
                orderBy.indexOf(`${orderKey}_`) >= 0 || !isMouseOver,
            })}
          >
            ▼
          </span>
        </Link>
      </th>
    );
  };

  const handleCurrentPage = (page) => {
    setCurrentPage(page);
  }

  const onKnowledgeTypeSelectedChanged = (event) => {

    let selectKnowledgeType = knowledgeTypeList.find(
      (p) => p.name == event.target.value
    );

    if (!selectKnowledgeType) return;
    setSelectKnowledgeType(selectKnowledgeType);

  };

  useEffect(() => {
    if (!filterData) return;

    console.log(filterData);
    const newData = [...filterData];

    switch (orderBy) {
      case "header_asc":
        newData.sort((a, b) =>
          a.header.localeCompare(b.header)
        );
        break;
      case "header_desc":
        newData.sort((a, b) =>
          b.header.localeCompare(a.header)
        );
        break;
      case "name_asc":
        newData.sort((a, b) =>
          a.name.localeCompare(b.name)
        );
        break;
      case "name_desc":
        newData.sort((a, b) =>
          b.name.localeCompare(a.name)
        );
        break;
      case "masterRate_asc":
        newData.sort((a, b) =>
          a.masterRate ? a.masterRate - b.masterRate : -1
        );
        break;
      case "masterRate_desc":
        newData.sort((a, b) =>
          b.masterRate ? b.masterRate - a.masterRate : -1
        );
        break;
      case "learnedTimes_asc":
        newData.sort((a, b) =>
          a.learnedTimes ? a.learnedTimes - b.learnedTimes : -1
        );
        break;
      case "learnedTimes_desc":
        newData.sort((a, b) =>
          b.learnedTimes ? b.learnedTimes - a.learnedTimes : -1
        );
        break;
      case "rightTimes_asc":
        newData.sort((a, b) =>
          a.rightTimes ? a.rightTimes - b.rightTimes : -1
        );
        break;
      case "rightTimes_desc":
        newData.sort((a, b) =>
          b.rightTimes ? b.rightTimes - a.rightTimes : -1
        );
        break;
      case "learnedTotalSeconds_asc":
        newData.sort((a, b) =>
          a.learnedTotalSeconds ? a.learnedTotalSeconds - b.learnedTotalSeconds : -1
        );
        break;
      case "learnedTotalSeconds_desc":
        newData.sort((a, b) =>
          b.learnedTotalSeconds ? b.learnedTotalSeconds - a.learnedTotalSeconds : -1
        );
        break;
    }
    setDisplayList(newData);
  }, [filterData, orderBy]);

  useEffect(() => {
    const temp = data.map((p) => {
      return { id: p.type, name: p.knowledgeTypeName };
    });
    const unqueKnowledgeTypeList = temp.filter(
      (obj, index) =>
        temp.findIndex(
          (item) => item.id === obj.id && item.name === obj.name
        ) === index
    );
    setKnowledgeTypeList(unqueKnowledgeTypeList);
    setSelectKnowledgeType(unqueKnowledgeTypeList[0]);
  }, [data])


  useEffect(() => {
    if (knowledgeTypeList && selectKnowledgeType) {
      const selectTypeDataList = data.filter((p) => {
        return p.type === selectKnowledgeType.id;
      });
      setFilterData(selectTypeDataList);
    }
  }, [selectKnowledgeType, knowledgeTypeList])

  return (
    <div>
      {knowledgeTypeList?.length > 1 && (
        <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
          <Select
            value={selectKnowledgeType?.name}
            onChange={(e) => onKnowledgeTypeSelectedChanged(e)}
            displayEmpty
          >
            {knowledgeTypeList.map((item) => {
              return (
                <MenuItem key={item.id} value={item.name}>
                  {item.name}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
      )}
      <table className={styles.table} cellSpacing={0}>
        <thead>
          <tr>
            <SortTH orderKey="header">主題</SortTH>
            <SortTH orderKey="name">知識點的名稱</SortTH>
            <SortTH orderKey="masterRate">掌握度</SortTH>
            <SortTH orderKey="learnedTimes">接觸次數</SortTH>
            <SortTH orderKey="rightTimes">答對次數</SortTH>
            <SortTH orderKey="learnedTotalSeconds">學習活動時間</SortTH>
          </tr>
        </thead>
        <tbody>
          {currentItems.map((item, index) => {
            return (
              <tr key={`tr_${index}`}>
                <td
                  data-label={"主題:"}
                >
                  {item.header && <Chip size="small" label={item.header} sx={{ mr: 1 }} />}
                </td>
                <td
                  data-label={"知識點的名稱:"}
                >
                  {item.name}
                </td>
                <td
                  data-label={"掌握度:"}
                >
                  {item.learnedTimes === 0 ? "未接觸" : (ToPercent(item.masterRate))}
                </td>
                <td
                  data-label={"接觸次數:"}
                >
                  {item.learnedTimes}
                </td>
                <td
                  data-label={"答對次數:"}
                >
                  {item.rightTimes}
                </td>
                <td
                  data-label={"學習活動時間:"}
                >
                  {item.learnedTimes === 0 ? "--" : (toHHMMSS(item.learnedTotalSeconds))}
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
      <Pagination totalPages={totalPages} currentPage={handleCurrentPage} dataLength={displayList.length} selectTypeChange={selectKnowledgeType} />
    </div>
  )
}

const SpringPage = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const params = useParams();
  const { selectExamPacakge, examPackageList, userId, userName } = location.state || {};
  const [tabValue, setTabValue] = useState("userSpring");
  const [newselectExamPacakge, setNewSelectExamPacakge] = useState(null);
  const [userSprintList, setUserSprintList] = useState([]);
  const [userKnowledgePointList, setUserKnowledgePointList] = useState([]);

  const fetchUserSprintRecord = async () => {
    await recordsApi
      .getUserSprint(params.enterpriseId, userId, newselectExamPacakge)
      .then((res) => {
        setUserSprintList(res.data);
      });
  };

  const fetchUserKnowledgePointRecord = async () => {
    await recordsApi
      .getUserKnowledgePoint(params.enterpriseId, userId, newselectExamPacakge)
      .then((res) => {
        setUserKnowledgePointList(res.data);
      });
  };

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const returnPage = () => {
    navigate(-1);
  }

  const onExamPackageSelectedChanged = (event) => {
    let selectExamPacakge = examPackageList.find(
      (p) => p.id == event.target.value
    );
    setNewSelectExamPacakge(selectExamPacakge.id);
  };

  useEffect(() => {
    // 取得在學習紀錄時選擇的課程。
    if (!selectExamPacakge) {
      // 預設第一筆課程
      setNewSelectExamPacakge(examPackageList[0].id);
    } else {
      setNewSelectExamPacakge(selectExamPacakge.id);
    }
  }, [])

  useEffect(() => {
    if (newselectExamPacakge) {
      fetchUserSprintRecord();
      fetchUserKnowledgePointRecord();
    }
  }, [newselectExamPacakge])

  return (
    <>
      <Box className={styles.returnBar} >
        <IconButton onClick={returnPage}>
          <Icon path={mdiArrowLeft} size={1} color={"#5BB6FF"} />
        </IconButton>
        <div className={styles.returnText} onClick={returnPage}>
          返回
        </div>
      </Box>
      <Box sx={{ display: "flex" }} >
        <Grid item container sx={{ width: "auto" }}>
          <Grid item className={styles.titleBar}>
            <h1>查看學習計畫 - {userName}</h1>
            <FormControl
              sx={{ m: 1, minWidth: 120, marginLeft: "15px", backgroundColor: "white" }}
              size="small">
              <Select
                value={newselectExamPacakge ?? ''}
                onChange={onExamPackageSelectedChanged}
                displayEmpty
              >
                {examPackageList.map((item) => {
                  return (
                    <MenuItem key={`package_${item.id}`} value={item.id}>
                      {item.name}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
      </Box>
      <Box>
        <div className={styles.card}>
          <Box sx={{ borderBottom: 1, borderColor: "divider", marginBottom: "15px" }}>
            <Tabs
              value={tabValue}
              variant="scrollable"
              onChange={handleTabChange}
            >
              <Tab
                label={"學習計畫"}
                value="userSpring"
              />
              <Tab
                label={"知識點"}
                value="userKnowledgePoint"
              />
            </Tabs>
          </Box>
          <TabPanel value={tabValue} index="userSpring">
            <UserSpringList data={userSprintList}></UserSpringList>
          </TabPanel>
          <TabPanel value={tabValue} index="userKnowledgePoint">
            <UserKnowledgePointList data={userKnowledgePointList}></UserKnowledgePointList>
          </TabPanel>
        </div>
      </Box>
    </>
  )
}


export default SpringPage;