import React, { useState, useEffect } from "react";
import { Box, Button, Grid, IconButton, TextField, Alert } from "@mui/material";
import styles from "./index.module.scss";
import enterpriseApi from "../../services/enterprise/enterpriseInfo";
import { useParams } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";
import Icon from "@mdi/react";
import {
  mdiPencil,
  mdiClose,
} from "@mdi/js";

// 驗證電子郵件格式是否正確
function validateEmail(email) {

  var re = /^(?:[a-z0-9!#$%&amp;'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&amp;'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])$/;
  return re.test(email);
}

const EnterpriseInfoPage = () => {
  const params = useParams();
  const { t } = useTranslation();
  const [emailIconState, setEmailIconState] = useState(false);
  const [emailSaveState, setEmailSaveState] = useState(false);
  const [email, setEmail] = useState("");
  const [currentEmail, setCurrentEmail] = useState("");
  const [emailFormat, setEmailFormat] = useState(false);

  const fetchEnterpirse = async () => {
    return enterpriseApi.getInfo(params.enterpriseId).then((res) => {
      return res.data;
    });
  };

  const fetchChangeEnterpriseEmail = async () => {
    let email = { alertTo: currentEmail }
    await enterpriseApi.changeEnterpriseEmail(params.enterpriseId, email);
    await fetchEnterpirse();
  };


  const {
    data: enterpriseInfo,
    error,
    isLoading,
  } = useQuery({
    queryKey: ["fetchEnterpirse", params.enterpriseId],
    queryFn: fetchEnterpirse,
  });

  // 取最新的值
  const { refetch } = useQuery({
    queryKey: ["fetchEnterpirse", params.enterpriseId],
    queryFn: fetchEnterpirse,
  });

  const handleEmailIconState = (e) => {
    setEmailIconState(e);
    setEmailSaveState(false);
    setEmailFormat(false);
  }

  const handleEmailSaveState = (InputEmail) => {

    if (
      validateEmail(InputEmail)
    ) {
      setEmailSaveState(true);
      setCurrentEmail(email);
      setEmail("");
      setTimeout(() => {
        setEmailIconState(false);
      }, 200);
    } else {
      setEmailFormat(true);
      setTimeout(() => {
        setEmailFormat(false)
      }, 3000);
    }
  }

  const handleChangeEmailText = (e) => {
    setEmail(e)
  }

  const handleEmailWarning = () => {
    setEmailFormat(false);
  }

  useEffect(() => {
    setEmailFormat(false);
  }, [email])

  useEffect(() => {
    if (enterpriseInfo && !enterpriseInfo.alertTo) {
      setCurrentEmail("尚未設定")
    }
  }, [enterpriseInfo])

  useEffect(()=>{
    const changeEmail = async () => {
      if (enterpriseInfo && currentEmail) {
        await fetchChangeEnterpriseEmail();
        refetch();
      }
    };
    changeEmail();
  }, [currentEmail, emailIconState])

  return (
    <>
      <Box>
        <Box>
          <div className={styles.title}>{enterpriseInfo?.name}</div>
          <div className={styles.description}>
            {t("EnterpriseInfoPage.Description")}
          </div>
        </Box>
        <Box className={styles.main_content}>
          <Grid container alignItems="start" direction="column">
            <div className={styles.card}>
              <div className={styles.card_title}>
                {t("EnterpriseInfoPage.Card_title")}
              </div>
              <div className={styles.card_enterprise_info}>
                <div className={styles.card_enterprise_info_left}>
                  {enterpriseInfo?.id}
                </div>
                <div className={styles.card_enterprise_info_right}>
                  <div>
                    {t("EnterpriseInfoPage.BuildTime")}
                    {enterpriseInfo?.createTime
                      ? new Date(
                        enterpriseInfo?.createTime
                      ).toLocaleDateString()
                      : "-"}
                  </div>
                  <div>
                    {t("EnterpriseInfoPage.ValidityTime")}
                    {enterpriseInfo?.expireTime
                      ? new Date(enterpriseInfo.expireTime).toLocaleDateString()
                      : "-"}
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.card}>
              <div className={styles.card_title_box}>
                <div>
                  <div className={styles.card_title}>通知信箱</div>
                  <div className={styles.card_subtitle}>系統通知將會寄送到此信箱</div>
                </div>
                <IconButton
                  className={styles.edit_icon}
                  onClick={() => {
                    handleEmailIconState(!emailIconState)
                  }}
                >
                  {emailIconState ? <Icon path={mdiClose} size={1} /> : <Icon path={mdiPencil} size={1} color={"#9E9E9E"} />}
                </IconButton>
              </div>
              <div className={styles.card_emailSet_Box}>
                {emailIconState ?
                  <Grid container style={{ justifyContent: "center" }}>
                    <Grid sm={7} item>
                      <TextField
                        className={`${emailSaveState ? styles.emailSet_input : ""}`}
                        fullWidth
                        required
                        size="small"
                        placeholder="請輸入Email..."
                        onChange={
                          (e) => {
                            handleChangeEmailText(e.target.value)
                          }
                        }
                        disabled={emailSaveState}
                      ></TextField>
                    </Grid>
                    <Grid className={styles.card_emailSet_right} sm={5} item>
                      {!emailSaveState ?
                        <Button
                          className={`${styles.emailSave_btn} ${!email ? styles.emailSave_btn_error : ""}`}
                          onClick={() => {
                            handleEmailSaveState(email)
                          }}
                          disabled={!email}
                        >
                          確認並儲存
                        </Button>
                        :
                        null
                      }
                    </Grid>
                  </Grid>
                  :
                  <div className={styles.card_emailSet_text} style={{ color: enterpriseInfo?.alertTo ? "#616161" : null }}>
                    {enterpriseInfo?.alertTo ? enterpriseInfo.alertTo : "尚未設定"}
                  </div>
                }
              </div>
            </div>

          </Grid>
        </Box>
      </Box>
      {emailFormat ?
        <div className={styles.emailAlertContainer}>
          <Alert severity="error" onClose={handleEmailWarning}>
            請輸入正確的信箱格式
          </Alert>
        </div>
        : null}
    </>
  );
};

export default EnterpriseInfoPage;
