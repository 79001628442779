import { fetchData, fetchDataWithRetry } from "../api";

const getInfo = async (enterpriseId) => {
    return fetchDataWithRetry(`/Enterprise/${enterpriseId}/EnterpriseInfo`);
}
const getChilds = async (enterpriseId, searchText = null) => {
    const path = `/Enterprise/${enterpriseId}/EnterpriseInfo/Childs`;
    const config = {
        query: {
            searchText: searchText
        }
    };
    return fetchDataWithRetry(path, config);
}

const createChild = async (enterpriseId, postData) => {
    const path = `/Enterprise/${enterpriseId}/EnterpriseInfo/Childs`;
    const config = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(postData),
    };
    return fetchDataWithRetry(path, config);
}


const getMailTemplate = async (enterpriseId, templateName) => {

    return fetchDataWithRetry(`/Enterprise/${enterpriseId}/EnterpriseInfo/EmailTemplate/${templateName}`);
}

const changeEnterpriseEmail = async (enterpriseId, email) => {
    const path = `/Enterprise/${enterpriseId}/EnterpriseInfo`;
    const config = {
        method: 'PATCH',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(email),
    };
    return fetchDataWithRetry(path, config);
}

export default {
    getInfo,
    getChilds,
    getMailTemplate,
    createChild,
    changeEnterpriseEmail
}
