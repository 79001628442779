
import {
    Box,
    MenuItem,
    Select,
    SvgIcon,
  } from "@mui/material";

import Icon from "@mdi/react";
import { useTranslation } from "react-i18next";
import { mdiEarth } from '@mdi/js';
import { useEffect, useState } from "react";



const LanguageSelector = ({styles}) => {
    const { i18n } = useTranslation();
    const options = [
      { value: 'zh-TW', label: '繁中' },
      { value: 'English', label:'English' },
    ];
    const languageTw = options[0].value
    const storedLanguage = localStorage.getItem('language');
    const [selectedValue, setSelectedValue] = useState(storedLanguage || languageTw);
  
    const handleChange = (selectedOption) => {
      if (selectedOption === 'zh-TW') {
        i18n.changeLanguage('zh-tw');
      } else {
        i18n.changeLanguage('en');
      }
  
      localStorage.setItem('language', selectedOption);
    };

    useEffect(()=>{
        setSelectedValue(storedLanguage)
    }, [storedLanguage])
  
    return (
      <Select
      sx={styles}
       labelId="language-select-label"
       value={selectedValue}
       onChange={(event) => {
       setSelectedValue(event.target.value);
       handleChange(event.target.value);
       }}
       renderValue={() => {
        return (
          <Box sx={{ display: "flex", gap: 1 }}>
            <SvgIcon color="primary">
              <Icon path={mdiEarth} size={1} />
            </SvgIcon>
            {selectedValue === 'zh-TW' ? '繁中' : 'English'}
          </Box>
        );
      }}>

       {options.map((option) => (
         <MenuItem key={option.value} value={option.value}>
         {option.label}
         </MenuItem>
       ))}
     </Select>
    );
  };

  export default LanguageSelector;