import { fetchData, fetchDataWithRetry } from "../api";

const getEnterprises = async (searchText) => {
    const path = `/Admin/Enterprise`;
    const config = {
        query: {
            searchText: searchText
        }
    };
    return fetchDataWithRetry(path, config);
}
const createEnterprise = async (postData) => {
    const path = `/Admin/Enterprise`;
    const config = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(postData),
    };
    return fetchDataWithRetry(path, config);
}

const patchEnterprise = async (enterpriseId, patchData) => {
    const path = `/Admin/Enterprise/${enterpriseId}`;
    const config = {
        method: 'PATCH',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(patchData),
    };
    return fetchDataWithRetry(path, config);
}


const changeStatus = async (enterpriseId, targetStatus) => {
    const patchData = {
        status: targetStatus
    };
    const path = `/Admin/Enterprise/${enterpriseId}/Status`;
    const config = {
        method: 'PATCH',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(patchData),
    };
    return fetchDataWithRetry(path, config);
}

export default {
    getEnterprises,
    createEnterprise,
    patchEnterprise,
    changeStatus
}
