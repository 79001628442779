import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useAuth } from "../../hooks/auth";
import {
  Grid,
  Button,
  TextField,
  InputAdornment,
  Snackbar,
  Alert,
  IconButton,
  CircularProgress,
  Tabs,
  Tab,
  Box,
} from "@mui/material";
import styles from "./index.module.scss";
import Icon from "@mdi/react";
import {
  mdiEye,
  mdiEyeOff,
  mdiDomain,
  mdiEmailOutline,
  mdiKeyOutline,
} from "@mdi/js";
import backgroundImg from "../../assets/pic_login_bg.png";
import logo from "../../assets/logo_tag_black_M.png";
import shiYunLogo from "../../assets/logo_shi_yun_temp@3x.png";
import { useTranslation } from "react-i18next";
import LanguageSelector from "../LanguageSelector";

const TabPanel = (props) => {
  const { children, value, index } = props;
  return (
    <div hidden={value !== index}>
      {value === index && <Box>{children}</Box>}
    </div>
  );
};

const Login = (props) => {
  const { t, i18n } = useTranslation();
  const [password, setPassword] = useState("");
  const [email, setEmail] = useState("");

  const [enterpriseId, setenterpriseId] = useState("");
  const [loading, setLoading] = useState(false);
  const [errorOpen, setErrorOpen] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [error, setError] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams({});
  const [emailHelperText, setEmailHelperText] = useState("");
  const [passwordHelperText, setPasswordHelperText] = useState("");
  const [showpassword, setShowpassword] = useState(false);
  const auth = useAuth();
  const authStore = useSelector((state) => state.auth);
  const navigate = useNavigate();
  const hostname = window.location.hostname

  const redirect = searchParams.get("redirect");

  const handlelogin = () => {
    setLoading(true);
    auth
      .login(enterpriseId, email, password)
      .then((res) => {
        if (!redirect) navigate("/");
        else navigate(decodeURIComponent(redirect));
      })
      .catch((err) => {
        console.log(err.message);
        switch (err.message) {
          case "UserPasswordValidFailed":
            setErrorMsg(t("LoginPage.ErrorMsg_userPasswordValidFailed"));
            break;
          case "UserExpired":
            setErrorMsg(t("LoginPage.ErrorMsg_userExpired"));
            break;
          case "UserSuspended":
            setErrorMsg(t("LoginPage.ErrorMsg_userSuspended"));
            break;
          case "EnterpriseDisabled":
            setErrorMsg(t("LoginPage.ErrorMsg_enterpriseDisabled"));
            break;
          case "EnterpriseExpired":
            setErrorMsg(t("LoginPage.ErrorMsg_enterpriseExpired"));
            break;

          default:
            setErrorMsg(t("LoginPage.ErrorMsg_default"));
            setEmailHelperText(t("LoginPage.HelperText_email"));
            setPasswordHelperText(t("LoginPage.HelperText_password"));
            setError(true);
            break;
        }
        setErrorOpen(true);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleErrorClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setErrorOpen(false);
  };
  const showPasswordHandler = () => {
    setShowpassword(!showpassword);
  };
  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      handlelogin();
    }
  };
  const currentHost = (hostname)=>{
    switch (hostname){
      case "admin-sy.tageasy.com.tw":
        return <img alt="Shi Yun" src={shiYunLogo} className={styles.logo} />;
      default:
        return <img alt="TAG EASY" src={logo} className={styles.logo} />;
    }
  }
  useEffect(() => {
    if (!authStore.authInitReady) return;
    if (authStore.isAuth) {
      navigate("/");
    }
  }, [authStore]);

  // useEffect(()=>{
  //   setPasswordHelperText(t("LoginPage.HelperText_password_default"));
  // },[i18n.language])


  return (
    <>
      <Grid container className={styles.container} justifyContent="center">
        <Grid
          container
          onKeyDown={handleKeyDown}
          style={{ maxWidth: "612px", flexDirection: "column" }}
        >
          <div className={styles.logoBar}>
            {currentHost(hostname)}
            <LanguageSelector 
            styles={{
              '.MuiOutlinedInput-input': {
                padding: '8px 30px 20px 13px !important',  
                fontSize: '0.8rem'
              },
              '.MuiOutlinedInput-notchedOutline': {
                border: 'none', 
              },
              '.css-1aohbzm-MuiSvgIcon-root':{
                fontSize: '1.3rem',
              },
              // '.MuiSvgIcon-root':{
              //   top:'0.5rem'
              // },
              '.css-hfutr2-MuiSvgIcon-root-MuiSelect-icon':{
                top:'0.5rem !important',
              },
              '.css-bpeome-MuiSvgIcon-root-MuiSelect-icon':{
                top:'0.5rem',
              },
            }}/>
          </div>
          <div className={styles.login_title}>
            <div className={styles.wellcome_msg}>
              {t("LoginPage.WellcomeMsg")}
            </div>
            <h4 className={styles.platform_name}>
              <span>{t("LoginPage.PlatformName_backend")}</span>
            </h4>
          </div>
          <Grid item xs={12}>
            <TextField
              error={error}
              margin="normal"
              required
              fullWidth
              size="small"
              variant="outlined"
              name="enterpriseId"
              placeholder={t("LoginPage.Placeholder_enterpriseCode")}
              autoFocus
              value={enterpriseId}
              onChange={(e) => {
                setenterpriseId(e.target.value);
              }}
              InputProps={{
                className: error ? styles.errorlineColor : styles.lineColor,
                startAdornment: (
                  <InputAdornment position="start">
                    <Icon path={mdiDomain} size={1} />
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              error={error}
              margin="normal"
              required
              fullWidth
              size="small"
              variant="outlined"
              name="email"
              placeholder={t("LoginPage.Placeholder_email")}
              autoFocus
              helperText={emailHelperText}
              value={email}
              onChange={(e) => {
                setEmail(e.target.value);
              }}
              InputProps={{
                className: error ? styles.errorlineColor : styles.lineColor,
                startAdornment: (
                  <InputAdornment position="start">
                    <Icon path={mdiEmailOutline} size={1} />
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              error={error}
              margin="normal"
              required
              size="small"
              fullWidth
              variant="outlined"
              name="password"
              type={showpassword ? "text" : "password"}
              helperText={passwordHelperText}
              value={password}
              onChange={(e) => {
                setPassword(e.target.value);
              }}
              placeholder={t("LoginPage.Placeholder_password")}
              autoComplete="on"
              InputProps={{
                className: error ? styles.errorlineColor : styles.lineColor,
                startAdornment: (
                  <InputAdornment position="start">
                    <Icon path={mdiKeyOutline} size={1} />
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={showPasswordHandler}
                      edge="end"
                    >
                      <Icon
                        path={showpassword ? mdiEye : mdiEyeOff}
                        size={0.8}
                        color="#757575"
                      />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item className={styles.loading_row}>
            {loading && <CircularProgress />}
          </Grid>
          <Grid item xs={12}>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              className={styles.btn_login}
              onClick={handlelogin}
            >
              {t("LoginPage.LogInBtn")}
            </Button>
          </Grid>
        </Grid>
        <Snackbar
          anchorOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
          open={errorOpen}
          autoHideDuration={6000}
          onClose={handleErrorClose}
        >
          <Alert
            onClose={handleErrorClose}
            severity="error"
            sx={{ width: "100%" }}
          >
            {errorMsg}
          </Alert>
        </Snackbar>
      </Grid>
    </>
  );
};

export default Login;
