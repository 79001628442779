import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    user: null,
    userId: null,
    avatar: null,
}

const userInfoSlice = createSlice({
    name: "userInfo",
    initialState,
    reducers: {
        setUserData(state, action) {
            const userInfo = action.payload;
            return { ...state, user: userInfo, avatar: userInfo.avatarUrl, userId: userInfo.id, enterpriseId: userInfo.enterpriseId };
        }
    }
});

export const {
    setUserData,
} = userInfoSlice.actions;

export default userInfoSlice.reducer