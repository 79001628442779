import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import {
  Box,
  Grid,
  Checkbox,
  IconButton,
  Button,
  Dialog,
  AppBar,
  Toolbar,
  List,
  ListItem,
  DialogTitle,
  Divider,
  Switch,
  Collapse,
  Snackbar,
  DialogActions,
  DialogContent,
  Alert,
} from "@mui/material";
import Icon from "@mdi/react";
import {
  mdiMagnify,
  mdiTextBox,
  mdiTrashCanOutline,
  mdiClose,
  mdiCheckBold,
  mdiCloseThick,
  mdiCalendarMonthOutline,
  mdiPencil
} from "@mdi/js";
import styles from "./index.module.scss";
import examPackageApi from "../../services/enterprise/examPackage";
import examPackageAdminApi from "../../services/admin/examPackage";
import { useSelector } from "react-redux";
import { useAuth } from "../../hooks/auth";
import ConfirmedDialog from "../ConfirmedDialog";
import { PermissionLevel } from "../../constants/PermissionLevel";
import { useTranslation } from "react-i18next";
import Pagination from "../Pagination"

const PackageAddFormDialog = ({
  btnText,
  dialogTitle,
  fetchData,
  existPackages,
}) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const [alertOpen, setAlertOpen] = useState(false);
  const [packageList, setPackageList] = useState([]);
  const [searchText, setSearchText] = useState("");
  const userStore = useSelector((state) => state.user);
  const param = useParams();
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setPackageList([]);
  };

  const handleSendClick = async () => {
    let requireList = packageList
      .filter((p) => p.checked)
      .map((p) => ({
        examPackageId: p.id,
        enableExplain: p.enableExplain ?? true,
        enableSimulator: p.enableSimulator ?? true,
      }));
    await examPackageApi
      .addExamPackages(param.enterpriseId, requireList)
      .then((res) => {
        fetchData();
        handleClose();
      })
      .catch((err) => {
        alert(t("CourseMaterials.SendClick_alert_addFail"));
      });
  };

  useEffect(() => {
    if (!open) return;
    fetchPackages();
  }, [open]);

  const fetchPackages = async () => {
    await examPackageAdminApi.getExamPackages(searchText).then((res) => {
      let newData = res.data
        .filter((item) => {
          return !existPackages.some((a) => a.id === item.id);
        })
        .map((item) => ({
          ...item,
          checked: false,
          enableExplain: true,
          enableSimulator:item.hasSimulator,
        }));
      setPackageList(newData);
    });
  };

  const handlePackageSelected = (event, examPackage) => {
    let newPackages = packageList.map((item) => {
      if (item.id === examPackage.id) {
        return { ...item, checked: event.target.checked };
      }
      return item;
    });
    setPackageList(newPackages);
  };
  const handleExplainChange = (event, examPackage) => {
    let newPackages = packageList.map((item) => {
      if (item.id === examPackage.id) {
        return { ...item, enableExplain: event.target.checked };
      }
      return item;
    });
    setPackageList(newPackages);
  };

  const handleSimulatorChange = (event, examPackage) => {
    let newPackages = packageList.map((item) => {
      if (item.id === examPackage.id) {
        return { ...item, enableSimulator: event.target.checked };
      }
      return item;
    });
    setPackageList(newPackages);
  };

  return (
    <div>
      <Button variant="contained" onClick={handleClickOpen}>
        {btnText}
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        scroll="paper"
        maxWidth="md"
        fullWidth
      >
        <DialogTitle>{dialogTitle}</DialogTitle>
        <DialogContent dividers>
          <table className={styles.table} cellSpacing="0">
            <thead>
              <tr>
                <th width="40"></th>
                <th>{t("CourseMaterials.Dialog_table_name")}</th>
                <th>{t("CourseMaterials.Dialog_table_source")}</th>
                <th>{t("CourseMaterials.Dialog_table_explanation")}</th>
                <th>開啟模擬考</th>
              </tr>
            </thead>
            <tbody>
              {packageList.map((item) => {
                return (
                  <tr key={`${item.id}`}>
                    <td>
                      <Checkbox
                        checked={item.checked}
                        onChange={(event) => handlePackageSelected(event, item)}
                      />
                    </td>
                    <td data-label={`${t("CourseMaterials.Dialog_table_name")}:`}>
                      {item.name}
                    </td>
                    <td data-label={`${t("CourseMaterials.Dialog_table_source")}:`}>
                      {item.contentProvider}
                    </td>
                    <td>
                      {item.checked && (
                        <Switch
                          checked={item.enableExplain}
                          onChange={(e) => handleExplainChange(e, item)}
                          defaultChecked
                        />
                      )}
                    </td>
                    <td>
                    {item.checked ? (item.hasSimulator ? (
                        <Switch
                          checked={item.enableSimulator}
                          onChange={(e) => handleSimulatorChange(e, item)}
                          defaultChecked
                        />
                        ) : (                        
                        <Switch disabled/>)
                    ) : null}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" onClick={handleClose}>
            {t("CourseMaterials.Dialog_cancelBtn")}
          </Button>
          <Button variant="contained" onClick={handleSendClick}>
            {t("CourseMaterials.Dialog_submitBtn")}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

const CourseMaterials = () => {
  const { t } = useTranslation();
  const [examPackages, setExamPackages] = useState([]);
  const [removeConfirmDialog, setRemoveConfirmDialog] = useState({
    open: false,
    targets: [],
  });
  const [editConfirmDialog, setEditConfirmDialog] = useState({
    open: false,
    targets: [],
  });
  const params = useParams();
  const authStore = useSelector((state) => state.auth);
  // 目前所在頁數
  const [currentPage, setCurrentPage] = useState(1);
  // 每頁顯示項目數量
  const itmesPerPage = 20;
  // 總共需要的頁數 
  const totalPages = Math.ceil(examPackages.length / itmesPerPage);
  // 計算該頁面最後 item 為第幾個。
  const indexOfLastItem = currentPage * itmesPerPage;
  // 計算該頁面開始 item 為第幾個。
  const indexOfFirstItem = indexOfLastItem - itmesPerPage;
  // 提取該頁面要顯示的資料
  const currentItems = examPackages.slice(indexOfFirstItem, indexOfLastItem);

  const fetchExamPackages = async () => {
    await examPackageApi.getExamPackages(params.enterpriseId).then((res) => {
        setExamPackages(
          res.data.map((item) => ({
            ...item,
            checked: false,
          }))
        );
    });
  };
  
  useEffect(() => {
    fetchExamPackages();
  }, []);

  const handleSelected = (event, targetItem) => {
    let newList = examPackages.map((item) => {
      if (item.id === targetItem.id) {
        return { ...item, checked: event.target.checked };
      }
      return item;
    });
    setExamPackages(newList);
  };

  const onBatchRemoveBtnClcik = () => {
    const targets = examPackages.filter((p) => p.checked);
    setRemoveConfirmDialog({
      ...removeConfirmDialog,
      open: true,
      targets: targets,
    });
  };

  const onRemoveCancleClick = () => {
    setRemoveConfirmDialog({
      ...removeConfirmDialog,
      open: false,
      targets: [],
    });
  };

  const onRemoveConfirmedClcik = async () => {
    let targets = removeConfirmDialog.targets.map((p) => p.id);
    await examPackageApi
      .removeExamPackage(params.enterpriseId, targets)
      .then((res) => {
        fetchExamPackages();
        setRemoveConfirmDialog({
          ...removeConfirmDialog,
          open: false,
          targets: [],
        });
      });
  };

  // 從 <PaginationBar> 取得當前所在頁數
  const handleCurrentPage = (page)=>{
    setCurrentPage(page)
  }

  const handleClose = () => {
    setEditConfirmDialog({
      ...editConfirmDialog,
      open: false,         
    })
    fetchExamPackages();
  };

  const handleExplainChange = (e) =>{
    let updatedItem = examPackages.map((item) => {
      if(item.id === editConfirmDialog.targets.id){
        setEditConfirmDialog({
          ...editConfirmDialog,
          targets: {
            ...editConfirmDialog.targets,
            enableExplain: e.target.checked,
          },
        });
        return {...item, enableExplain: e.target.checked}
      }
      return item;
    });
    setExamPackages(updatedItem);
  }

  const handleSimulatorChange = (e) => {
    let updatedItem = examPackages.map((item) => {
      if(item.id === editConfirmDialog.targets.id){
        setEditConfirmDialog({
          ...editConfirmDialog,
          targets: {
            ...editConfirmDialog.targets,
            enableSimulator: e.target.checked,
          },
        });
        return {...item, enableSimulator: e.target.checked}
      }
      return item;
    });
    setExamPackages(updatedItem);
  }

  const handleSendClick = async ()=>{
    let updatedItem = examPackages.find((item) => item.id === editConfirmDialog.targets.id);
    if (updatedItem) {
      updatedItem = { 
        examPackageId: updatedItem.id, 
        expireDate: updatedItem.authorizedCreateTime,
        enableExplain: updatedItem.enableExplain, 
        enableSimulator: updatedItem.enableSimulator
      };
    } 
    await examPackageApi
      .patchExamPackage(params.enterpriseId, updatedItem)
      .then((res) => {
        fetchExamPackages();
        setEditConfirmDialog({
          ...editConfirmDialog,
          open: false,         
        })
      })
      .catch((err) => {
        alert("修正失敗");
      });
  }

  return (
    <>
      <Box>
        <Box>
          <div className={styles.title}>{t("CourseMaterials.Title")}</div>
          <div className={styles.description}>
            {t("CourseMaterials.Description")}
          </div>
        </Box>
        <Grid item className={styles.card} container>
          <div className={styles.card_title}>
            {t("CourseMaterials.Card_title")}
          </div>
          <div className={styles.card_subtitle}>
            {t("CourseMaterials.Card_subtitle")}
          </div>
          <Grid
            container
            item
            alignItems="start"
            direction="row"
            justifyContent="flex-end"
            gap={1}
          >
            {authStore.permissionLevel >= PermissionLevel.SuperAdmin && (
              <>
                <Button
                  variant="outlined"
                  disabled={!examPackages?.some((p) => p.checked)}
                  onClick={() => onBatchRemoveBtnClcik()}
                >
                  {t("CourseMaterials.DeleteBtn")}
                </Button>
                <PackageAddFormDialog
                  btnText={t("CourseMaterials.PackageAddFormDialog_btnText")}
                  dialogTitle={t(
                    "CourseMaterials.PackageAddFormDialog_title"
                  )}
                  fetchData={fetchExamPackages}
                  existPackages={examPackages}
                />
              </>
            )}
          </Grid>
          <div>
            <table className={styles.table} cellSpacing="0">
              <thead>
                <tr>
                  <th width="40"></th>
                  <th>{t("CourseMaterials.Table_name")}</th>
                  <th>{t("CourseMaterials.Table_source")}</th>
                  <th>詳解</th>
                  <th>模擬考</th>
                  {authStore.permissionLevel >= PermissionLevel.SuperAdmin && (
                    <th style={{ paddingLeft: "10px" }}>
                      {t("CourseMaterials.Table_function")}
                    </th>
                  )}
                </tr>
              </thead>
              <tbody>
                {currentItems.map((item) => {
                  return (
                    <tr key={`pacakge_${item.id}`}>
                      <td>
                        {authStore.permissionLevel >=
                          PermissionLevel.SuperAdmin && (
                          <Checkbox
                            checked={item.checked}
                            onChange={(event) => handleSelected(event, item)}
                          />
                        )}
                      </td>
                      <td data-label={`${t("CourseMaterials.Table_name")}:`}>
                        {item.name}
                      </td>
                      <td data-label={`${t("CourseMaterials.Table_source")}:`}>
                        {item.contentProvider}
                      </td>
                      <td data-label={"詳解"}>
                        {item.enableExplain ? 
                          <Icon path={mdiCheckBold} size={1} color={"#51CF66"} /> 
                          : <Icon path={mdiCloseThick} size={1} color={"#FF9900"} />
                        }
                      </td>
                      <td data-label={"模擬考"}>
                        {item.hasSimulator ? 
                          (item.enableSimulator ? 
                            <Icon path={mdiCheckBold} size={1} color={"#51CF66"} />
                            :<Icon path={mdiCloseThick} size={1} color={"#FF9900"} />
                          ) 
                        : <Icon path={mdiCloseThick} size={1} color={"#D50000"} />
                        }
                      </td>
                      {authStore.permissionLevel >=
                        PermissionLevel.SuperAdmin && (
                        <td>
                          <IconButton
                            onClick={() =>
                              setEditConfirmDialog({
                                ...editConfirmDialog,
                                open: true,
                                targets: item,            
                              })
                            }
                          >
                            <Icon path={mdiPencil} size={1} color={"#9E9E9E"}/>
                          </IconButton>

                          <IconButton
                            className={styles.btn_del}
                            aria-label="delete"
                            onClick={() =>
                              setRemoveConfirmDialog({
                                ...removeConfirmDialog,
                                open: true,
                                targets: [item],
                              })
                            }
                          >
                            <Icon path={mdiTrashCanOutline} size={1} />
                          </IconButton>
                        </td>
                      )}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </Grid>
        <Pagination totalPages={totalPages} currentPage={handleCurrentPage} dataLength={examPackages.length}/>
      </Box>

      <ConfirmedDialog
        open={removeConfirmDialog.open}
        title={t("CourseMaterials.ConfirmedDialog_title")}
        onCancleClick={onRemoveCancleClick}
        onConfirmedClcik={onRemoveConfirmedClcik}
      >
        <>
          {removeConfirmDialog.targets?.length > 0 && (
            <div>
              {removeConfirmDialog.targets?.length > 1 ? (
                <>{t("CourseMaterials.ConfirmedDialog_text_dataAmount",{var : removeConfirmDialog?.targets.length})}</>
              ) : (
                <>
                  {t("CourseMaterials.ConfirmedDialog_text_remove")}
                  <strong>{removeConfirmDialog?.targets[0]?.name}</strong>？
                </>
              )}
            </div>
          )}
        </>
      </ConfirmedDialog>
      
      <Dialog
        open={editConfirmDialog.open}
        scroll="paper"
        maxWidth="sm"
        fullWidth
        onClose={handleClose}
      >
        <DialogTitle sx={{ borderBottom: '2px solid', borderBottomColor: '#BDBDBD' }}>
          <div className={styles.editDialogTitle} >
            <div>{editConfirmDialog.targets.name}</div>
            <IconButton 
            onClick={()=>{
              handleClose()
            }}>
              <Icon path={mdiClose} size={1} color={"#000000"}/>
            </IconButton>
          </div>
        </DialogTitle>
        <DialogContent sx={{marginTop:"20px"}}>
          <Grid className={styles.editDialogContent} >
            <div style={{color:"black"}}>試題詳解：</div>
            <Switch
              checked={editConfirmDialog.targets.enableExplain}
              onChange={(e) => handleExplainChange(e)}
             />
          </Grid>
          <Grid className={styles.editDialogContent} >
            <div>模擬考試：</div>
            {editConfirmDialog.targets.hasSimulator?            
            <Switch
              checked={editConfirmDialog.targets.enableSimulator}
              onChange={(e) => handleSimulatorChange (e)}
            />
            :<Switch disabled /> 
            }
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" onClick={handleClose}>
            {t("CourseMaterials.Dialog_cancelBtn")}
          </Button>
          <Button variant="contained" onClick={handleSendClick}>
            {t("CourseMaterials.Dialog_submitBtn")}
          </Button>
        </DialogActions>

      </Dialog>


    </>
  );
};

export default CourseMaterials;
