import { TextField, IconButton } from "@mui/material";
import { useState } from "react";
import Icon from "@mdi/react";
import { mdiMagnify } from '@mdi/js';
import { useTranslation } from "react-i18next";


const SearchBar = ({InputTextValue}) => {
  const { t } = useTranslation();

  // 更改 input 樣式
  const inputProps = {
    style: {
      width: "6.5rem",      
      fontSize: "0.8rem",   
      height: '0.5rem'
    },
  };
  
  // 輸入框的值
  const [inputValue, setInputValue] = useState("");
  // 當前輸入框的值
  const handleChange = (event) => {
    setInputValue(event.target.value);
  };
  // 點選內容搜尋按鈕時，輸入框的值
  const handleClick =()=>{
    InputTextValue(inputValue);
  }
  // enter 鍵也能使用
  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      handleClick();
    }
  };


  return (
    <>
      <TextField
        inputProps={{
        style: {
          width: "6.5rem",      
          fontSize: "0.8rem",   
          height: '0.5rem'
        }}}
        id="search"
        value={inputValue}
        onChange={handleChange}
        onKeyDown={handleKeyDown}
        InputProps={{
          endAdornment: (
              <IconButton onClick={handleClick}>
                <Icon path={mdiMagnify} size={1} />
              </IconButton>
          ),
        }}
        placeholder={t("SearchBar.Placeholder")}
      />
    </>
  );
};

export default SearchBar;
