import { fetchData, fetchDataWithRetry } from "../api";

const getCourses = async (enterpriseId, searchText = null) => {
    const path = `/Enterprise/${enterpriseId}/Courses`;
    const config = {
        query: {
            searchText: searchText
        }
    };
    return fetchDataWithRetry(path, config);
}

const getCourseInfo = async (enterpriseId, courseId) => {
    return fetchDataWithRetry(`/Enterprise/${enterpriseId}/Courses/${courseId}`)
}

const getCourseContent = async (enterpriseId, courseId, searchText = null) => {
    const path = `/Enterprise/${enterpriseId}/CourseContents/ExamPackages`;
    const config = {
        query: {
            courseId: courseId,
            searchText: searchText
        }
    };
    return fetchDataWithRetry(path, config);
}


const createCourses = async (enterpriseId, postData) => {
    const path = `/Enterprise/${enterpriseId}/Courses`;
    const config = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(postData),
    };
    return fetchDataWithRetry(path, config);
}


const changeStatus = async (enterpriseId, courseId, status) => {
    let patchData = {
        status: status
    };
    const path = `/Enterprise/${enterpriseId}/Courses/${courseId}/Status`;
    const config = {
        method: 'PATCH',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(patchData),
    };
    return fetchDataWithRetry(path, config);
}

const patchCourse = async (enterpriseId, courseId, patchData) => {
    const path = `/Enterprise/${enterpriseId}/Courses/${courseId}`;
    const config = {
        method: 'PATCH',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(patchData),
    };
    return fetchDataWithRetry(path, config);
}


const removeCourses = async (enterpriseId, courseIds) => {
    let deleteData = {
        courseIds: courseIds
    };
    const path = `/Enterprise/${enterpriseId}/Courses`;
    const config = {
        method: 'DELETE',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(deleteData),
    };
    return fetchDataWithRetry(path, config);
}

const patchCourseContent = async (enterpriseId, courseId, examPackageIds) => {
    let patchData = {
        courseId: courseId,
        examPackages: examPackageIds
    };
    const path = `/Enterprise/${enterpriseId}/CourseContents/ExamPackages`;
    const config = {
        method: 'PATCH',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(patchData),
    };
    return fetchDataWithRetry(path, config);

}

const getCourseStudnets = async (enterpriseId, courseId, searchText = null) => {
    const path = `/Enterprise/${enterpriseId}/Users`;
    const config = {
        query: {
            searchText,
            courseId,
        }
    };
    return fetchDataWithRetry(path, config);
}

const addCourseStudent = async (enterpriseId, courseId, userId) => {
    const path = `/Enterprise/${enterpriseId}/Courses/${courseId}/Users/${userId}`;
    const config = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
    };
    return fetchDataWithRetry(path, config);
}

const removeCourseStudnets = async (enterpriseId, courseId, userIds) => {
    let deleteData = {
        users: userIds
    };
    const path = `/Enterprise/${enterpriseId}/Courses/${courseId}/Users`;
    const config = {
        method: 'DELETE',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(deleteData),
    };
    return fetchDataWithRetry(path, config);
}

const addCourseStaff = async (enterpriseId, courseId, userId) => {
    const path = `/Enterprise/${enterpriseId}/Courses/${courseId}/Staffs/${userId}`;
    const config = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
    };
    return fetchDataWithRetry(path, config);
}

const removeCourseStaff = async (enterpriseId, courseId, userId) => {
    const path = `/Enterprise/${enterpriseId}/Courses/${courseId}/Staffs/${userId}`;
    const config = {
        method: 'DELETE',
        headers: {
            'Content-Type': 'application/json',
        },
    };
    return fetchDataWithRetry(path, config);
}


export default {
    getCourses,
    removeCourses,
    createCourses,
    patchCourse,
    getCourseInfo,
    changeStatus,
    getCourseContent,
    patchCourseContent,
    getCourseStudnets,
    addCourseStudent,
    removeCourseStudnets,
    addCourseStaff,
    removeCourseStaff
}
