import { useState ,useEffect } from "react";
import { Pagination, Box, TextField } from "@mui/material"; 
import { IconButton } from "@mui/material";
import Icon from "@mdi/react";
import { mdiMagnify } from '@mdi/js';
import { useTranslation } from "react-i18next";
import styles from "./index.module.scss";


const PaginationBar = ({currentPage, totalPages, inputText, dataLength, selectTypeChange})=> {
    const { t } = useTranslation();

    // 頁數輸入框的值
    const [inputValue, setInputValue] = useState("");
    // 當前頁數
    const [page, setPage] = useState(1); 
    // 輸入值判斷是否正確
    const [correctValue, setCorrectValue] = useState(1);

    // 當前所在頁數 (點選頁數按鈕時)
    const handleChange = (event, value) => { 
        setPage(value);
        currentPage(value);
    }; 
    // 頁數搜尋輸入框發生改變時
    const textFieldHandleChange = (event) => {
      setInputValue(event.target.value);
    };
    // 搜尋時判斷是否為數值
    const searchPageBtn = () => {
        setCorrectValue(Number(inputValue));
    }
    // enter 鍵也能使用
    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            searchPageBtn();
        }
      };
    // 搜尋頁面或點選頁面成功時，執行畫面移至最上方
    const scrollToTop = () => {
        window.scrollTo({
          top: 0,
          behavior: 'smooth' 
        });
      };

    // 搜尋或選擇器類型改變時從第一頁開始
    useEffect(()=>{
        setPage(1);
    },[inputText, selectTypeChange])

    // 防止警告提示一直存在
    useEffect(()=>{
        setInputValue("");
        setCorrectValue(page);
    },[page,inputText])

    // 取得正確的頁數，並更新
    useEffect(()=>{
        if(!isNaN(correctValue) && correctValue > 0 && correctValue <= totalPages){
            setPage(correctValue);
            currentPage(correctValue);
            scrollToTop();
        }
    },[correctValue])

    return ( 
        <>
        <Box className={styles.container}>
            <Box className={styles.paginationBox}> 
                <Pagination 
                    count={totalPages} 
                    page={page} 
                    onChange={handleChange} 
                    variant="outlined"
                    shape="circular"
                    color="secondary"
                    sx={{display:"flex", alignItems:"center"}}
                /> 
            </Box> 
            <Box className={styles.pageBar}>
                <div>{t("PaginationBar.TotalPage",{ var: totalPages})}
                <TextField
                inputProps={{
                    style: {
                    padding: "0px 0.5rem"
                }}}          
                value={inputValue}
                onChange={textFieldHandleChange}
                onKeyDown={handleKeyDown}
                sx={{
                        paddingTop: "0.4rem",
                        width: "3rem",
                    }}
                 />
                 {t("PaginationBar.TotalPage_text")}
                 <IconButton onClick={searchPageBtn}>
                    <Icon path={mdiMagnify} size={1} />
                </IconButton>
                 </div>
                    {dataLength > 0 && (
                        <>
                            {isNaN(correctValue) && <p className={styles.warnText}>{t("PaginationBar.WarnText_correct")}</p>}
                            {(correctValue > totalPages || correctValue <= 0) && <p className={styles.warnText}>{t("PaginationBar.WarnText_effective")}</p>}
                        </>
                    )}
            </Box>
        </Box>
    </>
    ); 
}

export default PaginationBar;