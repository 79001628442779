import React, { useEffect, useRef, useState } from "react";
import * as d3 from "d3";
import styles from "./index.module.scss"

const DonutChart = (props) => {
    const [data, setData] = useState([]);
    const ref = useRef(null);
    const chatRef = useRef(null);
    const zoomInRef = useRef(null);
    const zoomOutRef = useRef(null);
    let svg;
    let zoom;

    const width = 500;
    const height = 500;
    const margin = 50;
    const radius = Math.min(width, height) / 2 - margin;

    useEffect(() => {

        d3.select(chatRef.current).selectChild().remove();


        svg = d3.select(chatRef.current)
            .attr("width", width)
            .attr("height", height)
            .attr("viewBox", [-width / 2, -height / 2, width, height])
            .attr("style", "max-width: 100%; height: auto;");

        let mainContainer = svg.append("g");

        const color = d3.scaleOrdinal()
            .domain(data)
            .range(data.map(d => d.color));

        const arc = d3.arc()
            .innerRadius(radius * 0.85)
            .outerRadius(radius - 1);

        const pie = d3.pie()
            .padAngle(1 / radius)
            .sort(null)
            .value(d => d.value);

        const arcTarget = mainContainer.append("g")
            .selectAll()
            .data(pie(data))
            .join("path")
            .attr("fill", d => color(d))
            .attr("d", arc);

        arcTarget.transition()
            .duration(1000)
            .attrTween("d", (d) => {
                let d3Interpolate = d3.interpolate({ startAngle: 0, endAngle: 0 }, d);
                return (t) => {
                    return arc(d3Interpolate(t));
                };
            });
        arcTarget.append("title")
            .text(d => `${d.data.name}: ${d.data.display}`)

        mainContainer.append("g")
            .attr("font-family", "sans-serif")
            .attr("font-size", 12)
            .attr("text-anchor", "middle")
            .selectAll()
            .data(pie(data))
            .join("text")
            .attr("transform", d => `translate(${arc.centroid(d)})`)
        // .call(text => text.append("tspan")
        //     .attr("y", "-0.4em")
        //     .attr("font-weight", "bold")
        //     .text(d => d.data.name))
        // .call(text => text.filter(d => (d.endAngle - d.startAngle) > 0.25).append("tspan")
        //     .attr("x", 0)
        //     .attr("y", "0.7em")
        //     .attr("fill-opacity", 0.7)
        //     .text(d => d.data.value.toLocaleString("en-US")));


    }, [data]);


    useEffect(() => {
        if (!props.data || props.data.length == 0)
            return;

        if (props.data.every(p => p.value === 0)) {
            const emptyData = props.data.map(p => { return { ...p, value: 1, color: "#cccccc" } });
            setData(emptyData);
        }
        else {
            setData(props.data);
        }

    }, [props.data])

    return (
        <div className={styles.chatArea} >
            <svg ref={chatRef} >
            </svg>
        </div >
    );
};

export default DonutChart;