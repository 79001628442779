import React, { useEffect, useRef, useState } from "react";
import styles from "./index.module.scss";
import * as d3 from "d3";

const ActiveStatusHistogramChart = (props) => {
  const chatRef = useRef(null);
  const { data } = props;
  let svg;

  const [width, setWidth] = useState(calculateWidth());
  const height = 250;

  // 合適當前螢幕比例的圖表寬度
  function calculateWidth() {
    // 1000 (圖表寬度) : 1450 (螢幕寬度) 尺寸最為合適
    const baseWidth = 1000;
    const baseScreenWidth = 1450;
    const screenWidth = window.innerWidth;

    // 小於 900 時 (mui版面會改變(變大))，因此重新計算寬度。
    if (screenWidth < 900) {
      let belowNineHundredWidth = Math.max(baseWidth - (900 - screenWidth), 0);
      return belowNineHundredWidth;
    } else {
      // 計算變化後的寬度。
      const aboveNineHundredWidth = Math.max(
        baseWidth + (screenWidth - baseScreenWidth),
        0
      );
      return aboveNineHundredWidth;
    }
  }

  const handleResize = () => {
    setWidth(calculateWidth());
  };

  // 監聽螢幕比例變化
  const addResizeListener = () => {
    window.addEventListener("resize", handleResize);
  };

  const removeResizeListener = () => {
    window.removeEventListener("resize", handleResize);
  };

  useEffect(() => {
    d3.select(chatRef.current).selectChild().remove();
    if (!data || data.length === 0) return;

    svg = d3.select(chatRef.current).attr("viewBox", [0, 0, width, height]);

    let mainContainer = svg.append("g");

    const margin = { top: 20, right: 20, bottom: 30, left: 40 };

    const x = d3
      .scaleBand()
      .domain(data.map((d) => d.time.toString()))
      .range([margin.left, width - margin.right])
      .padding(0.8);

    let maxYNum = d3.max(data, (d) => d.value);
    if (maxYNum < 10) maxYNum = 10;

    const y = d3
      .scaleLinear()
      .domain([0, maxYNum])
      .nice()
      .range([height - margin.bottom, margin.top]);

    const xAxis = (g) =>
      g
        .attr("transform", `translate(0,${height - margin.bottom})`)
        .call(d3.axisBottom(x))
        .selectAll("text")
        .style("font-size", "25px");

    mainContainer
      .append("g")
      .attr("class", "chart_bar")
      .attr("fill", "#4463cb")
      .selectAll("rect")
      .data(data)
      .join("rect")
      .attr("x", (d) => x(d.time.toString()))
      .attr("y", (d) => y(d.value))
      .attr("height", (d) => y(0) - y(d.value))
      .attr("width", x.bandwidth());

    mainContainer.append("g").attr("class", "x-axis").call(xAxis);

    addResizeListener();

    return () => {
      removeResizeListener();
    };
  }, [data, width]);

  return (
    <div className={styles.chatArea}>
      <svg ref={chatRef}></svg>
    </div>
  );
};

export default ActiveStatusHistogramChart;
