import { fetchData, fetchDataWithRetry } from "../api";


const getExamPackages = async (searchText = null) => {
    const path = `/Admin/ExamPackage`;
    const config = {
        query: {
            filterName: searchText,
            pageSize: 1000,
            pageNum: 1
        }
    };
    return fetchDataWithRetry(path, config);
}


export default {
    getExamPackages
}
