import { fetchDataWithRetry, fetchDataWithProgress } from "../api";

const getTasks = async (enterpriseId) => {
    return fetchDataWithRetry(`/Enterprise/${enterpriseId}/Tasks`);
}


const addTask = async (enterpriseId, file, taskType, taskParams, onUploadProgress) => {
    const formData = new FormData();
    formData.append('file', file);
    formData.append('taskType', taskType);
    formData.append('taskParams', JSON.stringify(taskParams))

    const path = `/Enterprise/${enterpriseId}/Tasks`;
    const config = {
        method: 'POST',
        body: formData
    };
    return fetchDataWithProgress(path, config, onUploadProgress);
}

const removeTasks = async (enterpriseId, taskIds) => {
    let deleteData = {
        taskIds: taskIds
    };
    const path = `/Enterprise/${enterpriseId}/Tasks`;
    const config = {
        method: 'DELETE',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(deleteData),
    };
    return fetchDataWithRetry(path, config);
}


export default {
    getTasks,
    addTask,
    removeTasks
}
