import { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { Box, Button, Checkbox } from "@mui/material";
import styles from "./index.module.scss";
import Icon from "@mdi/react";
import { mdiArrowLeft, mdiTextBox, mdiClockOutline } from "@mdi/js";
import coursesApi from "../../services/enterprise/courses";
import { useTranslation } from "react-i18next";

const EditCourseMaterials = () => {
  // const userStore = useSelector(state => state.user);
  const { t } = useTranslation();
  const [searchText, setSearchText] = useState("");
  const [examPackages, setExamPackages] = useState([]);
  const [selected, setSelected] = useState([]);
  const params = useParams();
  useEffect(() => {
    const fetchCourses = async () => {
      await coursesApi
        .getExamPackages(searchText, params.enterpriseId)
        .then((res) => {
          setExamPackages(res.data);
        });
    };
    fetchCourses();
  }, []);
  const navigate = useNavigate();

  const onCheckboxChange = (id) => {
    let temp = [...selected];
    if (id) {
      if (temp.includes(id)) {
        temp = temp.filter((item) => item != id);
      } else {
        temp.push(id);
      }
    }
    setSelected(temp);
  };

  const onAddContents = async () => {
    await coursesApi
      .addContentsToCourse(params.enterpriseId, params.courseId, selected)
      .then((res) => navigate(-1));
  };

  return (
    <>
      <Box sx={{ padding: "24px" }}>
        <a className={styles.back_btn} onClick={() => navigate(-1)}>
          <Icon
            path={mdiArrowLeft}
            size={1}
            style={{ marginRight: "8px", color: "#5BB6FF" }}
          />
          {t("EditCourseMaterials.ReturnBtn")}
        </a>
        <Box>
          <p className={styles.title}>{t("EditCourseMaterials.Title")}</p>
          <p className={styles.subtitle}>{t("EditCourseMaterials.Subtitle")}</p>
        </Box>
        <Button onClick={onAddContents} className={styles.add_textbook_btn}>
          {t("EditCourseMaterials.ConfirmBtn")}
        </Button>

        <Box
          sx={
            examPackages.length
              ? {
                  boxShadow:
                    "0px 1px 1px rgba(9, 30, 66, 0.25), 0px 0px 1px rgba(9, 30, 66, 0.31)",
                  padding: "8px 0",
                  maxHeight: "50vh",
                  overflowY: "scroll",
                }
              : { padding: 0 }
          }
        >
          {examPackages.map((item) => {
            return (
              <div className={styles.listbox}>
                <p>
                  <Checkbox
                    checked={selected.includes(item.id)}
                    onChange={() => onCheckboxChange(item.id)}
                    inputProps={{ "aria-label": "controlled" }}
                  />
                  <Icon
                    path={mdiTextBox}
                    size={1}
                    style={{ marginRight: "8px" }}
                  />
                  <div className={styles.listItemTitle}>
                    <p>{item.name}</p>
                    <p className={styles.listItemSubtext}>
                      {item.contentProvider}
                    </p>
                  </div>
                </p>
                <p className={styles.listItemSubtext}>
                  <Icon path={mdiClockOutline} size={0.6} />
                  {t("EditCourseMaterials.ListItemSubtext")}
                  {item.expireDate}
                </p>
              </div>
            );
          })}
        </Box>
      </Box>
    </>
  );
};

export default EditCourseMaterials;
