import React, { useEffect, useState } from "react";
import styles from "./index.module.scss";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { useTranslation } from "react-i18next";

const ConfirmedDialog = (props) => {
  const { t } = useTranslation();
  const { open, title, onCancleClick, onConfirmedClcik } = props;
  return (
    <div>
      <Dialog open={open} onClose={onCancleClick} maxWidth="sm" fullWidth>
        <DialogTitle>{title}</DialogTitle>
        <DialogContent>
          <DialogContentText>{props.children}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" onClick={onCancleClick}>
            {t("ConfirmedDialog.CancelBtn")}
          </Button>
          <Button variant="contained" onClick={onConfirmedClcik}>
            {t("ConfirmedDialog.ConfirmBtn")}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default ConfirmedDialog;
