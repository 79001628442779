import React, { useState, useEffect, useContext, createContext } from "react";
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { setUserData } from '../reducers/user';
import { setUserAuth, reloadUserAuth, clearUserAuth } from '../reducers/auth';
import authApi from "../services/general/auth";
import myAccountApi from "../services/general/myAccount";
import { PermissionLevel } from "../constants/PermissionLevel";

const authContext = createContext();

export function AuthProvider({ children }) {
    const auth = useAuthProvider();
    return <authContext.Provider value={auth}>{children}</authContext.Provider>;
}

export const useAuth = () => {
    return useContext(authContext);
};

function useAuthProvider() {
    const authStore = useSelector(state => state.auth);
    const userStore = useSelector(state => state.user);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const params = useParams();
    const location = useLocation();

    useEffect(() => {
        dispatch(reloadUserAuth());
    }, []);

    useEffect(() => {
        if (!authStore?.userAuthInfo) {
            return;
        }
        if (!authStore.enterpriseId) {
            logout();
            return;
        }
        let now = Date.now();
        if (new Date(authStore.userAuthInfo.accessTokenExpiredTime) > now) {
            if (!userStore.user) {
                fetchUserInfo();
            }
        }
        else if (new Date(authStore.userAuthInfo.refreshTokenExpiredTime) > now) {
            logout();
            return;
        }
        else {
            logout();
            return;
        }
    }, [authStore?.userAuthInfo]);

    const fetchUserInfo = async () => {
        const userResponse = await myAccountApi.getUserInfo();
        dispatch(setUserData(userResponse.data));
    }

    const login = async (enterpriseId, username, password) => {
        await authApi.login(enterpriseId, username, password)
            .then(function (response) {
                let userAuth = response.data;
                dispatch(setUserAuth(userAuth));
            });
    };

    const logout = () => {
        dispatch(clearUserAuth());
        navigate("/login");
    }


    return {
        login,
        logout
    };
}