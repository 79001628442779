import { useState, useEffect, useRef } from "react";
import {
  Box,
  Grid,
  Select,
  MenuItem,
  FormControl,
  TextField,
  Button,
} from "@mui/material";
import classnames from "classnames";
import Icon from "@mdi/react";
import {
  mdiMagnify,
  mdiCalendarMonthOutline,
  mdiTextBox,
  mdiSizeXxl,
} from "@mdi/js";
import styles from "./index.module.scss";
import coursesApi from "../../services/enterprise/courses";
import recordsApi from "../../services/enterprise/records";
import examPackageApi from "../../services/enterprise/examPackage";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import UserImportFormDialog from "../UserImportFormDialog";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import ExcelJS from "exceljs";
import { useTimeUtils } from "../../hooks/timeUtils";
import { useTranslation } from "react-i18next";

const ToPercent = (num) => {
  return Number(Math.round(num * 100).toFixed(2));
};

const ToPercentByTotal = (num, total) => {
  if (total === 0) return 0;
  return Number(Math.round((num / total) * 100).toFixed(2));
};

const CourseDailyLearningTimeReport = () => {
  const { t } = useTranslation();
  const [reportList, setReportList] = useState(null);
  const [coursesList, setCoursesList] = useState([]);
  const [examPackageList, setExamPackages] = useState([]);
  const [selectCourse, setSelectCourse] = useState(null);
  const [selectExamPacakge, setSelectExamPacakge] = useState(null);
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [dateList, setDateList] = useState([]);
  const includeInitDate = {
    start: new Date(2022, 1, 1),
    end: new Date(),
  };
  const [includeDate, setIncludeDate] = useState(includeInitDate);
  const windowSize = useRef([window.innerWidth, window.innerHeight]);
  const params = useParams();
  const { toHHMMSS } = useTimeUtils();

  const mapUserRecordToEveryDate = (dataRecords) => {
    return dateList.map((dateItem) => {
      let value = 0;
      if (dataRecords) {
        let recordItem = Object.entries(dataRecords).find(
          (e) => new Date(e[0]) - new Date(dateItem) == 0
        );
        if (recordItem) {
          value = recordItem[1];
        }
      }
      return {
        date: dateItem,
        time: toHHMMSS(value),
      };
    });
  };

  const fetchCourses = async () => {
    const searchText = "";
    await coursesApi.getCourses(params.enterpriseId, searchText).then((res) => {
      if (!res.data) return;
      let filterCourses = res.data.filter((p) => p.status !== "UnPubliish");
      setCoursesList(res.data);
    });
  };
  const fetchPackages = async () => {
    const searchText = "";
    await coursesApi
      .getCourseContent(params.enterpriseId, selectCourse.id, searchText)
      .then((res) => {
        setExamPackages(res.data);
      });
  };

  const handleDateChange = ([newStartDate, newEndDate]) => {
    setStartDate(newStartDate);
    setEndDate(newEndDate);
    if (newStartDate && newEndDate && newEndDate - newStartDate >= 0) {
      let tempDateList = [];
      for (
        let currentDate = new Date(newStartDate);
        new Date(newEndDate) - currentDate >= 0;
        currentDate.setDate(currentDate.getDate() + 1)
      ) {
        tempDateList.push(currentDate.toLocaleDateString());
      }
      setDateList(tempDateList);
    }
  };
  const handleReportDownload = () => {
    const workbook = new ExcelJS.Workbook();
    const sheet = workbook.addWorksheet(
      t("ReportPage.LearningTimeReport_title")
    );
    sheet.properties.defaultColWidth = 10;
    sheet.getCell("A1").value = `${t(
      "ReportPage.LearningTimeReport_cell_class"
    )}${selectCourse.name}`;
    sheet.getCell("A2").value = `${t(
      "ReportPage.LearningTimeReport_cell_students"
    )}${reportList.length}`;
    sheet.getCell("A3").value = `${t(
      "ReportPage.LearningTimeReport_cell_course"
    )}${selectExamPacakge.name}`;
    sheet.mergeCells("A1:B1");
    sheet.mergeCells("A2:B2");
    sheet.mergeCells("A3:B3");
    sheet.getColumn("B").width = 40;
    sheet.getColumn("C").width = 13;
    let columns = [
      { name: t("ReportPage.LearningTimeReport_columns_name") },
      { name: t("ReportPage.LearningTimeReport_columns_account") },
      { name: t("ReportPage.LearningTimeReport_columns_learningTime") },
      ...dateList.map((p) => {
        return {
          name: p,
        };
      }),
    ];
    let rows = [];
    reportList.forEach((reportItem) => {
      let rowData = [
        reportItem.fullName,
        reportItem.enterpriseUserName,
        toHHMMSS(reportItem.traningSecond),
        ...mapUserRecordToEveryDate(reportItem.dateRecords).map((p) => p.time),
      ];
      rows.push(rowData);
    });
    sheet.addTable({
      name: t("ReportPage.LearningTimeReport_tableName"),
      ref: "A4",
      headerRow: true,

      columns: columns,
      rows: rows,
    });
    sheet.views = [{ state: "frozen", xSplit: 2, ySplit: 4 }];

    workbook.xlsx.writeBuffer().then((content) => {
      const link = document.createElement("a");
      const blobData = new Blob([content], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;",
      });
      link.download = t("ReportPage.LearningTimeReport_downloadName");
      link.href = URL.createObjectURL(blobData);
      link.click();
    });
  };

  const onCourseSelectedChanged = (event) => {
    let selectCourse = coursesList.find((p) => p.id == event.target.value);
    if (!selectCourse) return;
    setSelectCourse(selectCourse);
  };
  const onExamPackageSelectedChanged = (event) => {
    let selectExamPacakge = examPackageList.find(
      (p) => p.id == event.target.value
    );
    if (!selectExamPacakge) return;
    setSelectExamPacakge(selectExamPacakge);
  };

  useEffect(() => {
    fetchCourses();
  }, []);

  useEffect(() => {
    if (!selectCourse || !selectCourse.createTime) {
      setIncludeDate(includeInitDate);
      return;
    }
    let start = new Date(new Date(selectCourse.createTime).toDateString());
    let end = new Date();
    if (selectCourse.expireTime && new Date(selectCourse.expireTime) <= end)
      end = new Date(new Date(selectCourse.expireTime).toDateString());
    setIncludeDate({ start, end });

    if (
      startDate < start ||
      startDate > end ||
      endDate < start ||
      endDate > end
    ) {
      setStartDate(null);
      setEndDate(null);
    }
  }, [selectCourse]);

  useEffect(() => {
    if (!selectCourse) setExamPackages([]);
    else fetchPackages();
  }, [selectCourse]);

  const handleReportSubmit = async () => {
    let startDateStr = startDate.toLocaleDateString();
    let endDateStr = endDate.toLocaleDateString();
    await recordsApi
      .getDailyReport(
        params.enterpriseId,
        selectCourse.id,
        selectExamPacakge.id,
        startDateStr,
        endDateStr
      )
      .then((res) => {
        setReportList(res.data);
      });
  };

  return (
    <>
      <Grid item container sx={{ width: "auto" }}>
        <Grid item>
          <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
            <Select
              value={selectCourse?.id ?? ""}
              onChange={(e) => onCourseSelectedChanged(e)}
              displayEmpty
            >
              <MenuItem disabled value="">
                <em>
                  {t("ReportPage.LearningTimeReport_item_class")}
                </em>
              </MenuItem>
              {coursesList.map((item) => {
                return (
                  <MenuItem key={item.id} value={item.id}>
                    {item.name}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </Grid>
        <Grid item>
          <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
            <Select
              value={selectExamPacakge?.id ?? ""}
              onChange={onExamPackageSelectedChanged}
              displayEmpty
            >
              <MenuItem disabled value="">
                <em>
                  {t("ReportPage.LearningTimeReport_item_course")}
                </em>
              </MenuItem>
              {examPackageList.map((item) => {
                return (
                  <MenuItem key={item.id} value={item.id}>
                    {item.name}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </Grid>
        <Grid item>
          <div className={styles.datePickerContainer}></div>
          <div>
            <DatePicker
              selectsRange={true}
              startDate={startDate}
              endDate={endDate}
              onChange={(date) => handleDateChange(date)}
              placeholderText={t("ReportPage.LearningTimeReport_date_placeholderText")}
              dateFormat="yyyy/MM/dd"
              includeDateIntervals={[includeDate]}
              customInput={
                <TextField
                  id="outlined-basic"
                  variant="outlined"
                  size="small"
                  sx={{ m: 1, minWidth: 120 }}
                />
              }
              withPortal={windowSize.current[0] < 600}
            />
          </div>
        </Grid>
      </Grid>
      <Grid
        container
        item
        alignItems="start"
        direction="row"
        justifyContent="flex-end"
        gap={1}
      >
        <Button
          variant="outlined"
          disabled={!reportList}
          onClick={handleReportDownload}
        >
          {t("ReportPage.LearningTimeReport_button_download")}
        </Button>
        <Button variant="contained" onClick={handleReportSubmit}>
          {t("ReportPage.LearningTimeReport_button_generate")}
        </Button>
      </Grid>
      <Grid container item xs={12}>
        {reportList && (
          <div className={styles.table_wrapper}>
            <table className={styles.table} cellSpacing="0">
              <thead>
                <tr>
                  <th
                    className={classnames(
                      styles.sticky_col,
                      styles.sticky_col_one
                    )}
                  >
                    {t("ReportPage.LearningTimeReport_table_name")}
                  </th>
                  <th>{t("ReportPage.LearningTimeReport_table_account")}</th>
                  <th>{t("ReportPage.LearningTimeReport_table_learningTime")}</th>
                  {dateList.map((dateItem) => {
                    return <th key={dateItem}>{dateItem}</th>;
                  })}
                </tr>
              </thead>
              <tbody>
                {reportList?.map((item) => {
                  return (
                    <tr>
                      <td
                        data-label={`${t(
                          "ReportPage.LearningTimeReport_table_name"
                        )}:`}
                        className={classnames(
                          styles.sticky_col,
                          styles.sticky_col_one
                        )}
                      >
                        {item.fullName}
                      </td>
                      <td
                        data-label={`${t(
                          "ReportPage.LearningTimeReport_table_account"
                        )}:`}
                      >
                        {item.enterpriseUserName}
                      </td>
                      <td
                        data-label={`${t(
                          "ReportPage.LearningTimeReport_table_learningTime"
                        )}:`}
                      >
                        {toHHMMSS(item.traningSecond)}
                      </td>
                      {mapUserRecordToEveryDate(item.dateRecords).map(
                        (dateItem) => {
                          return (
                            <td data-label={`${dateItem.date}:`}>
                              {dateItem.time}
                            </td>
                          );
                        }
                      )}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        )}
      </Grid>
    </>
  );
};

const CoursePackageLearningReport = () => {
  const { t } = useTranslation();
  const [reportList, setReportList] = useState(null);
  const [coursesList, setCoursesList] = useState([]);
  const [examPackageList, setExamPackages] = useState([]);
  const [selectCourse, setSelectCourse] = useState(null);
  const [selectExamPacakge, setSelectExamPacakge] = useState(null);
  const params = useParams();
  const { toHHMMSS } = useTimeUtils();

  const fetchCourses = async () => {
    const searchText = "";
    await coursesApi.getCourses(params.enterpriseId, searchText).then((res) => {
      if (!res.data) return;
      let filterCourses = res.data.filter((p) => p.status !== "UnPubliish");
      setCoursesList(filterCourses);
    });
  };

  const fetchPackages = async () => {
    const searchText = "";
    await coursesApi
      .getCourseContent(params.enterpriseId, selectCourse.id, searchText)
      .then((res) => {
        setExamPackages(res.data);
      });
  };

  const handleReportDownload = () => {
    const workbook = new ExcelJS.Workbook();
    const sheet = workbook.addWorksheet(
      t("ReportPage.CoursePackageLearningReport_title")
    );
    sheet.properties.defaultColWidth = 12;
    sheet.getCell("A1").value = `${t(
      "ReportPage.CoursePackageLearningReport_cell_class"
    )}${selectCourse.name}`;
    sheet.getCell("A2").value = `${t(
      "ReportPage.CoursePackageLearningReport_cell_students"
    )}${reportList.length}`;
    sheet.getCell("A3").value = `${t(
      "ReportPage.CoursePackageLearningReport_cell_course"
    )}${selectExamPacakge.name}`;
    sheet.mergeCells("A1:B1");
    sheet.mergeCells("A2:B2");
    sheet.mergeCells("A3:B3");
    sheet.getColumn("B").width = 40;
    sheet.getColumn("C").width = 13;
    let columns = [
      { name: t("ReportPage.CoursePackageLearningReport_columns_name") },
      { name: t("ReportPage.CoursePackageLearningReport_columns_account") },
      { name: t("ReportPage.CoursePackageLearningReport_columns_totalLearningTime") },
      { name: t("ReportPage.CoursePackageLearningReport_columns_mastery") },
      { name: t("ReportPage.CoursePackageLearningReport_columns_completion") },
    ];

    let rows = [];
    reportList.forEach((reportItem) => {
      let rowData = [
        reportItem.fullName,
        reportItem.enterpriseUserName,
        toHHMMSS(reportItem.traningSecond),
        ToPercent(reportItem.masterRate) + "%",
        ToPercentByTotal(
          reportItem.learnedQuestionCount,
          reportItem.totalQuestionCount
        ) + "%",
      ];
      rows.push(rowData);
    });
    sheet.addTable({
      name: t("ReportPage.CoursePackageLearningReport_tableName"),
      ref: "A4",
      headerRow: true,

      columns: columns,
      rows: rows,
    });
    sheet.views = [{ state: "frozen", xSplit: 0, ySplit: 4 }];

    workbook.xlsx.writeBuffer().then((content) => {
      const link = document.createElement("a");
      const blobData = new Blob([content], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;",
      });
      link.download = t("ReportPage.CoursePackageLearningReport_downloadName");
      link.href = URL.createObjectURL(blobData);
      link.click();
    });
  };

  const onCourseSelectedChanged = (event) => {
    let selectCourse = coursesList.find((p) => p.id == event.target.value);
    if (!selectCourse) return;
    setSelectCourse(selectCourse);
  };

  const onExamPackageSelectedChanged = (event) => {
    let selectExamPacakge = examPackageList.find(
      (p) => p.id == event.target.value
    );
    if (!selectExamPacakge) return;
    setSelectExamPacakge(selectExamPacakge);
  };

  useEffect(() => {
    fetchCourses();
  }, []);

  useEffect(() => {
    if (!selectCourse) setExamPackages([]);
    else fetchPackages();
  }, [selectCourse]);

  const handleReportSubmit = async () => {
    await recordsApi
      .getExamPackageReport(
        params.enterpriseId,
        selectCourse.id,
        selectExamPacakge.id
      )
      .then((res) => {
        setReportList(res.data);
      });
  };

  return (
    <>
      <Grid item container sx={{ width: "auto" }}>
        <Grid item>
          <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
            <Select
              value={selectCourse?.id ?? ""}
              onChange={(e) => onCourseSelectedChanged(e)}
              displayEmpty
            >
              <MenuItem disabled value="">
                <em>{t("ReportPage.CoursePackageLearningReport_item_class")}</em>
              </MenuItem>
              {coursesList.map((item) => {
                return (
                  <MenuItem key={item.id} value={item.id}>
                    {item.name}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </Grid>
        <Grid item>
          <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
            <Select
              value={selectExamPacakge?.id ?? ""}
              onChange={onExamPackageSelectedChanged}
              displayEmpty
            >
              <MenuItem disabled value="">
                <em>{t("ReportPage.CoursePackageLearningReport_item_course")}</em>
              </MenuItem>
              {examPackageList.map((item) => {
                return (
                  <MenuItem key={item.id} value={item.id}>
                    {item.name}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </Grid>
      </Grid>
      <Grid
        container
        item
        alignItems="start"
        direction="row"
        justifyContent="flex-end"
        gap={1}
      >
        <Button
          variant="outlined"
          disabled={!reportList}
          onClick={handleReportDownload}
        >
          {t("ReportPage.CoursePackageLearningReport_button_download")}
        </Button>
        <Button variant="contained" onClick={handleReportSubmit}>
          {t("ReportPage.CoursePackageLearningReport_button_generate")}
        </Button>
      </Grid>
      <Grid container item xs={12}>
        {reportList && (
          <div className={styles.table_wrapper}>
            <table className={styles.table} cellSpacing="0">
              <thead>
                <tr>
                  <th
                    className={classnames(
                      styles.sticky_col,
                      styles.sticky_col_one
                    )}
                  >
                    {t("ReportPage.CoursePackageLearningReport_table_name")}
                  </th>
                  <th>{t("ReportPage.CoursePackageLearningReport_table_account")}</th>
                  <th>{t("ReportPage.CoursePackageLearningReport_table_totalLearningTime")}</th>
                  <th>{t("ReportPage.CoursePackageLearningReport_table_mastery")}</th>
                  <th>{t("ReportPage.CoursePackageLearningReport_table_completion")}</th>
                </tr>
              </thead>
              <tbody>
                {reportList?.map((item, index) => {
                  return (
                    <tr key={index}>
                      <td
                        data-label={`${t(
                          "ReportPage.CoursePackageLearningReport_table_name"
                        )}:`}
                        className={classnames(
                          styles.sticky_col,
                          styles.sticky_col_one
                        )}
                      >
                        {item.fullName}
                      </td>
                      <td
                        data-label={`${t(
                          "ReportPage.CoursePackageLearningReport_table_account"
                        )}:`}
                      >
                        {item.enterpriseUserName}
                      </td>
                      <td
                        data-label={`${t(
                          "ReportPage.CoursePackageLearningReport_table_totalLearningTime"
                        )}:`}
                      >
                        {toHHMMSS(item.traningSecond)}
                      </td>
                      <td
                        data-label={`${t(
                          "ReportPage.CoursePackageLearningReport_table_mastery"
                        )}:`}
                      >
                        {ToPercent(item.masterRate)}%
                      </td>
                      <td
                        data-label={`${t(
                          "ReportPage.CoursePackageLearningReport_table_completion"
                        )}:`}
                      >
                        {ToPercentByTotal(
                          item.learnedQuestionCount,
                          item.totalQuestionCount
                        )}
                        %
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        )}
      </Grid>
    </>
  );
};

const PackageKnowledgePointMasteryReport = () => {
  const { t } = useTranslation();
  const [reportList, setReportList] = useState(null);
  const [selectKnowledgeType, setSelectKnowledgeType] = useState(null);
  const [knowledgeTypeList, setKnowledgeTypeList] = useState([]);
  const [coursesList, setCoursesList] = useState([]);
  const [examPackageList, setExamPackages] = useState([]);
  const [selectCourse, setSelectCourse] = useState(null);
  const [selectExamPacakge, setSelectExamPacakge] = useState(null);
  const params = useParams();

  const fetchCourses = async () => {
    const searchText = "";
    await examPackageApi
      .getCourses(params.enterpriseId, selectExamPacakge.id)
      .then((res) => {
        if (!res.data) return;
        let filterCourses = res.data.filter((p) => p.status !== "UnPubliish");
        setCoursesList(filterCourses);
      });
  };

  const fetchPackages = async () => {
    await examPackageApi.getExamPackages(params.enterpriseId).then((res) => {
      setExamPackages(res.data);
    });
  };

  const handleReportDownload = () => {
    const workbook = new ExcelJS.Workbook();

    knowledgeTypeList.forEach((currentKnowledgeType) => {
      const sheet = workbook.addWorksheet(currentKnowledgeType.name);
      sheet.properties.defaultColWidth = 18;
      sheet.getCell("A1").value = `${t(
        "ReportPage.PackageKnowledgePointMasteryReport_cell_course"
      )}${selectExamPacakge.name}`;
      if (selectCourse) {
        sheet.getCell("A2").value = `${t(
          "ReportPage.PackageKnowledgePointMasteryReport_cell_class"
        )}${selectCourse.name}`;
        sheet.getCell("A3").value = `${t(
          "ReportPage.PackageKnowledgePointMasteryReport_cell_students"
        )}${reportList.length}`;
      }
      sheet.mergeCells("A1:B1");
      sheet.mergeCells("A2:B2");
      sheet.mergeCells("A3:B3");
      sheet.getColumn("A").width = 30;
      let columns = [
        { name: t("ReportPage.PackageKnowledgePointMasteryReport_columns_knowledgePoint") },
        { name: t("ReportPage.PackageKnowledgePointMasteryReport_columns_averageMastery") },
        { name: t("ReportPage.PackageKnowledgePointMasteryReport_columns_learners") },
        { name: t("ReportPage.PackageKnowledgePointMasteryReport_columns_times") },
      ];

      let rows = [];
      reportList
        .filter((p) => p.knowledgeType == currentKnowledgeType.id)
        .forEach((reportItem) => {
          let rowData = [
            reportItem.pointName,
            ToPercent(reportItem.avgMasterRate) + "%",
            reportItem.learnedUserAmount,
            reportItem.learnedCount,
          ];
          rows.push(rowData);
        });
      sheet.addTable({
        name: currentKnowledgeType.name,
        ref: "A4",
        headerRow: true,

        columns: columns,
        rows: rows,
      });
      sheet.views = [{ state: "frozen", xSplit: 0, ySplit: 4 }];
    });
    workbook.xlsx.writeBuffer().then((content) => {
      const link = document.createElement("a");
      const blobData = new Blob([content], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;",
      });
      link.download = t(
        "ReportPage.PackageKnowledgePointMasteryReport_downloadName"
      );
      link.href = URL.createObjectURL(blobData);
      link.click();
    });
  };

  const onCourseSelectedChanged = (event) => {
    if (event.target.value === "") {
      setSelectCourse(null);
      return;
    }
    let selectCourse = coursesList.find((p) => p.id == event.target.value);
    if (!selectCourse) return;
    setSelectCourse(selectCourse);
  };

  const onExamPackageSelectedChanged = (event) => {
    let selectExamPacakge = examPackageList.find(
      (p) => p.id == event.target.value
    );
    if (!selectExamPacakge) return;
    setSelectExamPacakge(selectExamPacakge);
  };

  const onKnowledgeTypeSelectedChanged = (event) => {
    let selectKnowledgeType = knowledgeTypeList.find(
      (p) => p.id == event.target.value
    );
    if (!selectKnowledgeType) return;
    setSelectKnowledgeType(selectKnowledgeType);
  };
  useEffect(() => {
    fetchPackages();
  }, []);

  useEffect(() => {
    if (!selectExamPacakge) setCoursesList([]);
    else fetchCourses();
  }, [selectExamPacakge]);

  const handleReportSubmit = async () => {
    await recordsApi
      .getKnowledgePointReport(
        params.enterpriseId,
        selectExamPacakge.id,
        selectCourse?.id
      )
      .then((res) => {
        if (res.data.length > 0) {
          const temp = res.data.map((p) => {
            return { id: p.knowledgeType, name: p.knowledgeTypeName };
          });
          const unqueKnowledgeTypeList = temp.filter(
            (obj, index) =>
              temp.findIndex(
                (item) => item.id === obj.id && item.name === obj.name
              ) === index
          );
          setKnowledgeTypeList(unqueKnowledgeTypeList);
          setSelectKnowledgeType(unqueKnowledgeTypeList[0]);
          setReportList(res.data);
        }
      });
  };

  return (
    <>
      <Grid item container sx={{ width: "auto" }}>
        <Grid item>
          <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
            <Select
              value={selectExamPacakge?.id ?? ""}
              onChange={onExamPackageSelectedChanged}
              displayEmpty
            >
              <MenuItem disabled value="">
                <em>
                  {t("ReportPage.PackageKnowledgePointMasteryReport_item_course")}
                </em>
              </MenuItem>
              {examPackageList.map((item) => {
                return (
                  <MenuItem key={item.id} value={item.id}>
                    {item.name}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </Grid>
        <Grid item>
          <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
            <Select
              value={selectCourse?.id ?? ""}
              onChange={(e) => onCourseSelectedChanged(e)}
              displayEmpty
            >
              <MenuItem value="">
                {t("ReportPage.PackageKnowledgePointMasteryReport_item_class")}
              </MenuItem>
              {coursesList.map((item) => {
                return (
                  <MenuItem key={item.id} value={item.id}>
                    {item.name}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </Grid>
      </Grid>
      <Grid
        container
        item
        alignItems="start"
        direction="row"
        justifyContent="flex-end"
        gap={1}
      >
        <Button
          variant="outlined"
          disabled={!reportList}
          onClick={handleReportDownload}
        >
          {t("ReportPage.PackageKnowledgePointMasteryReport_button_download")}
        </Button>
        <Button variant="contained" onClick={handleReportSubmit}>
          {t("ReportPage.PackageKnowledgePointMasteryReport_button_generate")}
        </Button>
      </Grid>
      <Grid container item xs={12}>
        {reportList && (
          <div className={styles.table_wrapper}>
            {knowledgeTypeList?.length > 1 && (
              <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
                <Select
                  value={selectKnowledgeType?.id}
                  onChange={(e) => onKnowledgeTypeSelectedChanged(e)}
                  displayEmpty
                >
                  {knowledgeTypeList.map((item) => {
                    return (
                      <MenuItem key={item.id} value={item.id}>
                        {item.name}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            )}
            <table className={styles.table} cellSpacing="0">
              <thead>
                <tr>
                  <th
                    className={classnames(
                      styles.sticky_col,
                      styles.sticky_col_one
                    )}
                  >
                    {t("ReportPage.PackageKnowledgePointMasteryReport_table_knowledgePoint")}
                  </th>
                  <th>
                    {t("ReportPage.PackageKnowledgePointMasteryReport_table_averageMastery")}
                  </th>
                  <th>
                    {t("ReportPage.PackageKnowledgePointMasteryReport_table_learners")}
                  </th>
                  <th>
                    {t("ReportPage.PackageKnowledgePointMasteryReport_table_times")}
                  </th>
                </tr>
              </thead>
              <tbody>
                {reportList
                  ?.filter((p) => p.knowledgeType == selectKnowledgeType.id)
                  .map((item, index) => {
                    return (
                      <tr key={index}>
                        <td
                          data-label={`${t(
                            "ReportPage.PackageKnowledgePointMasteryReport_table_knowledgePoint"
                          )}:`}
                          className={classnames(
                            styles.sticky_col,
                            styles.sticky_col_one
                          )}
                        >
                          {item.pointName}
                        </td>
                        <td
                          data-label={`${t(
                            "ReportPage.PackageKnowledgePointMasteryReport_table_averageMastery"
                          )}:`}
                        >
                          {ToPercent(item.avgMasterRate)}%
                        </td>
                        <td
                          data-label={`${t(
                            "ReportPage.PackageKnowledgePointMasteryReport_table_learners"
                          )}`}
                        >
                          {item.learnedUserAmount}
                        </td>
                        <td
                          data-label={`${t(
                            "ReportPage.PackageKnowledgePointMasteryReport_table_times"
                          )}`}
                        >
                          {item.learnedCount}
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          </div>
        )}
      </Grid>
    </>
  );
};

const PreTestScoreReport = () => {
  const { t } = useTranslation();
  const [reportList, setReportList] = useState(null);
  const [coursesList, setCoursesList] = useState([]);
  const [examPackageList, setExamPackages] = useState([]);
  const [selectCourse, setSelectCourse] = useState(null);
  const [selectExamPacakge, setSelectExamPacakge] = useState(null);
  const params = useParams();

  const fetchCourses = async () => {
    const searchText = "";
    await examPackageApi
      .getCourses(params.enterpriseId, selectExamPacakge.id)
      .then((res) => {
        if (!res.data) return;
        let filterCourses = res.data.filter((p) => p.status !== "UnPubliish");
        setCoursesList(filterCourses);
      });
  };

  const fetchPackages = async () => {
    await examPackageApi.getExamPackages(params.enterpriseId).then((res) => {
      setExamPackages(res.data);
    });
  };

  const handleReportDownload = () => {
    const workbook = new ExcelJS.Workbook();

    const sheet = workbook.addWorksheet(
      t("ReportPage.PreTestScoreReport_title")
    );
    sheet.properties.defaultColWidth = 18;
    sheet.getCell("A1").value = `${t(
      "ReportPage.PreTestScoreReport_examPackageCell"
    )}${selectExamPacakge.name}`;

    if (selectCourse) {
      sheet.getCell("A2").value = `${t(
        "ReportPage.PreTestScoreReport_courseNameCell"
      )}${selectCourse.name}`;
    }

    sheet.mergeCells("A1:B1");
    sheet.mergeCells("A2:B2");
    sheet.getColumn("A").width = 30;
    let columns = [
      { name: t("ReportPage.PreTestScoreReport_fullNameHeader") },
      { name: t("ReportPage.PreTestScoreReport_userIdHeader") },
      { name: t("ReportPage.PreTestScoreReport_startTimeHeader") },
      { name: t("ReportPage.PreTestScoreReport_doneTimeHeader") },
      { name: t("ReportPage.PreTestScoreReport_durationHeader") },
      { name: t("ReportPage.PreTestScoreReport_scoreHeader") },
    ];

    let rows = [];
    reportList
      .forEach((reportItem) => {
        let rowData = [
          reportItem.fullName,
          reportItem.enterpriseUserName,
          reportItem.startTime,
          reportItem.doneTime,
          reportItem.duration,
          reportItem.score,
        ];
        rows.push(rowData);
      });
    sheet.addTable({
      name: t("ReportPage.PreTestScoreReport_title"),
      ref: "A3",
      headerRow: true,
      columns: columns,
      rows: rows,
    });
    sheet.views = [{ state: "frozen", xSplit: 0, ySplit: 3 }];
    sheet.columns.forEach(column => {
      let maxLength = 50;
      column.eachCell({ includeEmpty: true }, cell => {
        const columnLength = cell.value ? cell.value.toString().length : 0;
        if (columnLength > maxLength) {
          maxLength = columnLength;
        }
      });
      column.width = maxLength;
    });
    workbook.xlsx.writeBuffer().then((content) => {
      const link = document.createElement("a");
      const blobData = new Blob([content], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;",
      });
      link.download = `${t("ReportPage.PreTestScoreReport_title")}.xlsx`;
      link.href = URL.createObjectURL(blobData);
      link.click();
    });
  };

  const onCourseSelectedChanged = (event) => {
    if (event.target.value === "") {
      setSelectCourse(null);
      return;
    }
    let selectCourse = coursesList.find((p) => p.id == event.target.value);
    if (!selectCourse) return;
    setSelectCourse(selectCourse);
  };

  const onExamPackageSelectedChanged = (event) => {
    let selectExamPacakge = examPackageList.find(
      (p) => p.id == event.target.value
    );
    if (!selectExamPacakge) return;
    setSelectExamPacakge(selectExamPacakge);
  };

  useEffect(() => {
    fetchPackages();
  }, []);

  useEffect(() => {
    if (!selectExamPacakge) setCoursesList([]);
    else fetchCourses();
  }, [selectExamPacakge]);

  const handleReportSubmit = async () => {
    await recordsApi
      .getPreTestScoreReport(
        params.enterpriseId,
        selectExamPacakge.id,
        selectCourse?.id
      )
      .then((res) => {
        if (res.data.length > 0) {
          let result = res.data.map((d) => {
            return {
              ...d,
              startTime: new Date(d.startTime).toLocaleString(),
              doneTime: (d.doneTime ? new Date(d.doneTime).toLocaleString() : t("ReportPage.PreTestScoreReport_unDone")),
              duration: (d.doneTime ? d.duration : "-"),
              score: (d.doneTime ? d.score : "-")
            }
          });
          setReportList(result);
        }
      });
  };

  return (
    <>
      <Grid item container sx={{ width: "auto" }}>
        <Grid item>
          <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
            <Select
              value={selectExamPacakge?.id ?? ""}
              onChange={onExamPackageSelectedChanged}
              displayEmpty
            >
              <MenuItem disabled value="">
                <em>
                  {t("ReportPage.PreTestScoreReport_examPackageMenuItem")}
                </em>
              </MenuItem>
              {examPackageList.map((item) => {
                return (
                  <MenuItem key={item.id} value={item.id}>
                    {item.name}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </Grid>
        <Grid item>
          <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
            <Select
              value={selectCourse?.id ?? ""}
              onChange={(e) => onCourseSelectedChanged(e)}
              displayEmpty
            >
              <MenuItem value="">
                {t("ReportPage.PreTestScoreReport_courseMenuItem")}
              </MenuItem>
              {coursesList.map((item) => {
                return (
                  <MenuItem key={item.id} value={item.id}>
                    {item.name}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </Grid>
      </Grid>
      <Grid
        container
        item
        alignItems="start"
        direction="row"
        justifyContent="flex-end"
        gap={1}
      >
        <Button
          variant="outlined"
          disabled={!reportList}
          onClick={handleReportDownload}
        >
          {t("ReportPage.PreTestScoreReport_downloadBtn")}
        </Button>
        <Button variant="contained" onClick={handleReportSubmit}>
          {t("ReportPage.PreTestScoreReport_submitBtn")}
        </Button>
      </Grid>
      <Grid container item xs={12}>
        {reportList && (
          <div className={styles.table_wrapper}>
            <table className={styles.table} cellSpacing="0">
              <thead>
                <tr>
                  <th
                    className={classnames(
                      styles.sticky_col,
                      styles.sticky_col_one
                    )}
                  >
                    {t("ReportPage.PreTestScoreReport_fullNameHeader")}
                  </th>
                  <th>
                    {t("ReportPage.PreTestScoreReport_userIdHeader")}
                  </th>
                  <th>
                    {t("ReportPage.PreTestScoreReport_startTimeHeader")}
                  </th>
                  <th>
                    {t("ReportPage.PreTestScoreReport_doneTimeHeader")}
                  </th>
                  <th>
                    {t("ReportPage.PreTestScoreReport_durationHeader")}
                  </th>
                  <th>
                    {t("ReportPage.PreTestScoreReport_scoreHeader")}
                  </th>
                </tr>
              </thead>
              <tbody>
                {reportList
                  .map((item, index) => {
                    return (
                      <tr key={index}>
                        <td
                          data-label={`${t(
                            "ReportPage.PreTestScoreReport_fullNameHeader"
                          )
                            }: `}
                          className={classnames(
                            styles.sticky_col,
                            styles.sticky_col_one
                          )}
                        >
                          {item.fullName}
                        </td>
                        <td
                          data-label={`${t(
                            "ReportPage.PreTestScoreReport_userIdHeader"
                          )
                            }: `}
                        >
                          {item.enterpriseUserName}
                        </td>
                        <td
                          data-label={`${t(
                            "ReportPage.PreTestScoreReport_startTimeHeader"
                          )
                            }: `}
                        >
                          {item.startTime}
                        </td>
                        <td
                          data-label={`${t(
                            "ReportPage.PreTestScoreReport_doneTimeHeader"
                          )
                            }: `}
                        >
                          {item.doneTime}
                        </td>
                        <td
                          data-label={`${t(
                            "ReportPage.PreTestScoreReport_durationHeader"
                          )
                            }: `}
                        >
                          {item.duration}
                        </td>
                        <td
                          data-label={`${t(
                            "ReportPage.PreTestScoreReport_scoreHeader"
                          )
                            }: `}
                        >
                          {item.score}
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          </div>
        )}
      </Grid >
    </>
  );
};



const ReportPage = () => {
  const { t } = useTranslation();
  const [selectReportType, setSelectReportType] = useState(3);

  return (
    <>
      <Box>
        <Box>
          <div className={styles.title}>{t("ReportPage.Title")}</div>
          <div className={styles.description}>
            {t("ReportPage.Description")}
          </div>
        </Box>
        <Grid direction="row" className={styles.gard_card} container>
          <div className={styles.gard_card_icon}>
            {t("ReportPage.Card_title_classList")}
          </div>
          <div className={styles.gard_card_list}>
            <ul className={styles.card_subtitle}>
              <li>{t("ReportPage.Card_subtitle_dailyLearningTime")}</li>
              <li>{t("ReportPage.Card_subtitle_classCourseLearning")}</li>
              <li>{t("ReportPage.Card_subtitle_courseKnowledge")}</li>
              {/* <li>課程題目報表：顯示班級在課程中各題目的表現狀況</li> */}
            </ul>
          </div>
        </Grid>
        {/* <Stack direction="row" spacing={1}>

            </Stack> */}
        <Box className={styles.main_content}>
          <Grid item className={styles.card} container>
            <Grid item container alignItems={"center"}>
              <div>{t("ReportPage.Card_title_reportType")}</div>
              <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
                <Select
                  value={selectReportType}
                  onChange={(e) => setSelectReportType(e.target.value)}
                >
                  <MenuItem value={3}>
                    {t("ReportPage.reportType_item_knowledgePoint")}
                  </MenuItem>
                  <MenuItem value={1}>
                    {t("ReportPage.reportType_item_learningTime")}
                  </MenuItem>
                  <MenuItem value={2}>
                    {t("ReportPage.reportType_item_courseLearning")}
                  </MenuItem>
                  <MenuItem value={4}>
                    {t("ReportPage.reportType_item_testScore")}
                  </MenuItem>
                  {/* <MenuItem value={4}>課程題目報表</MenuItem> */}
                </Select>
              </FormControl>
            </Grid>
            <Grid item container direction={"row"} alignItems={"center"}>
              <Grid item>{t("ReportPage.Card_title_reportConditions")}</Grid>
              {selectReportType == 1 && <CourseDailyLearningTimeReport />}
              {selectReportType == 2 && <CoursePackageLearningReport />}
              {selectReportType == 3 && <PackageKnowledgePointMasteryReport />}
              {selectReportType == 4 && <PreTestScoreReport />}
            </Grid>
          </Grid>
        </Box>
      </Box>
    </>
  );
};

export default ReportPage;
