import { useEffect, useCallback } from "react";
import { useTranslation } from "react-i18next";

export const useUserUtils = () => {
  const { t } = useTranslation();
  const displayStatus = useCallback((item) => {
    switch (item.userStatus) {
      case "Suspended":
      case "Disabled":
        return (
          <span style={{ color: "#ff9900" }}>
            {t("Hooks_userUtils.StatusBtn_disabled")}
          </span>
        );
      case "Enabled":
        if (item.expireTime) {
          if (Date.now() > new Date(item.expireTime))
            return (
              <span style={{ color: "#D50000" }}>
                {t("Hooks_userUtils.StatusBtn_expired")}
              </span>
            );
        }
        return (
          <span style={{ color: "#62AF00" }}>
            {t("Hooks_userUtils.StatusBtn_enabled")}
          </span>
        );
    }
  }, []);

  const displayRoles = useCallback((item) => {
    if (!item.roles) return "-";
    var roleStr = [];
    if (item.roles.includes("SuperAdmin"))
      roleStr.push(t("Hooks_userUtils.Role_name_superAdministrator"));
    if (item.roles.includes("DealerAdmin"))
      roleStr.push(t("Hooks_userUtils.Role_name_dealerAdministrator"));
    if (item.roles.includes("EnterpriseAdmin"))
      roleStr.push(t("Hooks_userUtils.Role_name_administrator"));
    if (item.roles.includes("Teacher")) roleStr.push(t("Hooks_userUtils.Role_name_teacher"));
    if (item.roles.includes("Student")) roleStr.push(t("Hooks_userUtils.Role_name_student"));
    return roleStr.join("、");
  }, []);

  return { displayStatus, displayRoles };
};
