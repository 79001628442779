import { useState, useEffect, useRef } from "react";
import {
  Box,
  Grid,
  Checkbox,
  Stepper,
  Button,
  Step,
  StepLabel,
  Radio,
  FormHelperText,
  DialogActions,
  FormControl,
  FormLabel,
  CircularProgress,
  LinearProgress,
  FormControlLabel,
  Select,
  MenuItem,
  RadioGroup,
  TextField,
  Stack,
} from "@mui/material";
import Icon from "@mdi/react";
import { mdiPencil, mdiTrashCanOutline, mdiTextBox } from "@mdi/js";
import styles from "./index.module.scss";
import coursesApi from "../../services/enterprise/courses";
import usersApi from "../../services/enterprise/users";
import taskApi from "../../services/enterprise/tasks";
import { useSelector } from "react-redux";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";
import ExcelJS from "exceljs";
import { useTimeUtils } from "../../hooks/timeUtils";
import classnames from "classnames";
import { useTranslation } from "react-i18next";

const CourseImport = ({ onBackClick }) => {
  const { t } = useTranslation();
  const [activeStep, setActiveStep] = useState(0);
  const [importDatas, setImportDatas] = useState([]);
  const [courseList, setCourseList] = useState();
  const [teacherList, setTeacherList] = useState();
  const [selectedFile, setSelectedFile] = useState(null);
  const [helperText, setHelperText] = useState("");
  const [override, setOverride] = useState(false);
  const [checkResult, setCheckResult] = useState({
    isDone: false,
    errorAmount: 0,
    sameAmount: 0,
  });
  const [uploadProgress, setUploadProgress] = useState({
    isDone: false,
    porgress: 0,
  });
  const [allowNextStep, setAllowNextStep] = useState(false);
  const steps = [
    t("CourseImport.Steps_select"),
    t("CourseImport.Steps_verification"),
    t("CourseImport.Steps_Delivery"),
  ];
  const params = useParams();
  const { validateDate } = useTimeUtils();

  const fetchCourses = async () => {
    await coursesApi.getCourses(params.enterpriseId).then((res) => {
      setCourseList(res.data);
    });
  };

  const checkHeader = (row) => {
    const headers = [
      t("CourseImport.CheckHeader_className"),
      t("CourseImport.CheckHeader_beginTime"),
      t("CourseImport.CheckHeader_expirationTime"),
    ];
    if (headers.some((p, index) => row.values[index + 1] !== p)) {
      setHelperText(t("CourseImport.HelperText"));
      throw new Error(t("CourseImport.HelperText_error"));
    }
  };

  const readXlsxFile = () => {
    return new Promise((resolve, reject) => {
      const importDatas = [];
      const wb = new ExcelJS.Workbook();
      try {
        const reader = new FileReader();
        reader.readAsArrayBuffer(selectedFile);
        reader.onload = () => {
          const buffer = reader.result;
          wb.xlsx.load(buffer).then((workbook) => {
            workbook.eachSheet((sheet, id) => {
              sheet.eachRow((row, rowIndex) => {
                if (rowIndex == 1) {
                  checkHeader(row);
                  return;
                }
                let courseName = row.values[1]?.toString().trim();
                let startTime = row.values.length > 1 ? row.values[2] : null;
                let expireTime = row.values.length > 2 ? row.values[3] : null;

                if (validateDate(startTime))
                  startTime = new Date(startTime).toLocaleDateString();
                if (validateDate(expireTime))
                  expireTime = new Date(expireTime).toLocaleDateString();
                let teachers = [];
                for (var i = 4; i <= row.values.length; i++) {
                  if (row.values[i]) {
                    teachers.push(row.values[i].text || row.values[i]);
                  }
                }
                if (courseName) {
                  importDatas.push({
                    errors: [],
                    courseName: courseName?.trim(),
                    startTime: startTime,
                    expireTime: expireTime,
                    teachers: teachers,
                  });
                }
              });
            });
            resolve(importDatas);
          });
        };
      } catch (err) {
        console.error(err);
        reject(err);
      }
    });
  };

  const handleStep = async () => {
    if (activeStep === 0) {
      const importDatas = await readXlsxFile();
      setImportDatas(importDatas);
    } else if (activeStep === steps.length - 1) {
      onBackClick();
      return;
    }
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    if (activeStep === 0 || activeStep === steps.length - 1) {
      onBackClick();
    } else {
      setActiveStep((prevActiveStep) => prevActiveStep - 1);
    }
  };

  const fetchTeachers = async () => {
    await usersApi
      .getUsers(params.enterpriseId, null, ["Teacher"])
      .then((res) => {
        setTeacherList(res.data);
      });
  };

  const checkData = () => {
    let sameAmount = 0;
    importDatas.forEach((item) => {
      let errors = [];
      if (!item.courseName || item.courseName.length === 0)
        errors.push(t("CourseImport.ErrorMsg_className"));

      if (courseList?.some((p) => p.name === item.courseName)) sameAmount++;
      //   errors.push("課程名稱已存在");

      if (
        importDatas.filter((p) => p.courseName === item.courseName).length > 1
      )
        errors.push(t("CourseImport.ErrorMsg_classNameDuplicate"));

      if (item.startTime && !validateDate(item.startTime))
        errors.push(t("CourseImport.ErrorMsg_beginTime"));

      if (item.expireTime && !validateDate(item.expireTime))
        errors.push(t("CourseImport.ErrorMsg_expirationTime"));

      const teacherIds = teacherList?.map((p) => p.enterpriseUserId) ?? [];
      if (item.teachers.some((teacher) => teacherIds.indexOf(teacher) < 0))
        errors.push(t("CourseImport.ErrorMsg_teacherAccountNotExist"));

      if (errors.length > 0) {
        item.errors = errors;
      }
    });
    console.log(sameAmount);
    setCheckResult({
      isDone: true,
      errorAmount: importDatas.filter((p) => p.errors.length > 0).length,
      sameAmount: sameAmount,
    });
  };

  const onUploadProgress = ({ loaded, total }) => {
    let percent = Math.floor((loaded * 100) / total);
    setUploadProgress({
      isDone: false,
      porgress: percent,
    });
  };

  const submitTask = async () => {
    setUploadProgress({
      isDone: false,
      porgress: 0,
    });
    const taskParams = {
      override: override,
      timeoffset: new Date().getTimezoneOffset(),
    };
    await taskApi
      .addTask(
        params.enterpriseId,
        selectedFile,
        "ImportCourses",
        taskParams,
        onUploadProgress
      )
      .then((res) => {
        setUploadProgress({
          isDone: true,
          porgress: 100,
        });
      });
  };

  useEffect(() => {
    if (!courseList) return;
    if (!teacherList) return;
    checkData();
  }, [courseList, teacherList]);

  useEffect(() => {
    switch (activeStep) {
      case 0:
        setCheckResult({
          isDone: false,
          errorAmount: 0,
          sameAmount: 0,
        });
        break;
      case 1:
        fetchCourses();
        fetchTeachers();
        break;
      case 2:
        submitTask();
        break;
    }
  }, [activeStep]);

  useEffect(() => {
    setAllowNextStep(false);
    if (activeStep === 0 && selectedFile) setAllowNextStep(true);
    if (activeStep === 1 && checkResult.isDone && checkResult.errorAmount === 0)
      setAllowNextStep(true);
    if (activeStep === 2) setAllowNextStep(true);
  }, [activeStep, selectedFile, checkResult]);

  return (
    <Grid container item className={styles.card}>
      <Grid item>
        <Stepper activeStep={activeStep}>
          {steps.map((label, index) => {
            return (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            );
          })}
        </Stepper>
      </Grid>
      <Grid item className={styles.card}>
        <Box>
          <Grid container direction="column">
            {activeStep === 0 && (
              <>
                <Grid
                  item
                  container
                  direction="row"
                  className={styles.gard_card}
                >
                  <div className={styles.gard_card_icon}>
                    {t("CourseImport.List_title")}
                  </div>
                  <div className={styles.gard_card_list}>
                    <ul className={styles.card_subtitle}>
                      <li>{t("CourseImport.Subtitle_notDuplicated")}</li>
                      <li>{t("CourseImport.Subtitle_beforehandCreated")}</li>
                      <li>{t("CourseImport.Subtitle_immediatelyActivated")}</li>
                      <li>{t("CourseImport.Subtitle_canChangeContent")}</li>
                      <li>
                        <a href="/course_sample.xlsx">
                          {t("CourseImport.DownloadBtn")}
                        </a>
                      </li>
                    </ul>
                  </div>
                </Grid>
                <Grid item container direction={"column"} gap={2}>
                  <div>{t("CourseImport.SelectExcel_title")}</div>
                  <FormControl>
                    <TextField
                      type="file"
                      inputProps={{
                        accept:
                          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
                      }}
                      onChange={(e) => setSelectedFile(e.target.files[0])}
                    />
                    <FormHelperText error>{helperText}</FormHelperText>
                  </FormControl>
                  <FormControl>
                    <FormLabel id="demo-row-radio-buttons-group-label">
                      {t("CourseImport.CheckBox_title")}
                    </FormLabel>
                    <RadioGroup
                      row
                      value={override}
                      onChange={(e) => setOverride(e.target.value)}
                    >
                      <FormControlLabel
                        value={false}
                        control={<Radio />}
                        label={t("CourseImport.CheckBox_skip")}
                      />
                      <FormControlLabel
                        value={true}
                        control={<Radio />}
                        label={t("CourseImport.CheckBox_override")}
                      />
                    </RadioGroup>
                  </FormControl>
                </Grid>
              </>
            )}
            {activeStep === 1 && (
              <>
                <Grid item container direction={"column"}>
                  <div>{t("CourseImport.CircularProgress_totalData", { var: importDatas?.length })}</div>
                  <div>
                    {t("CourseImport.CircularProgress_classData")}
                    {!courseList ? <CircularProgress /> : t("CourseImport.CircularProgress_classData_complete")}
                  </div>
                  <div>
                    {t("CourseImport.CircularProgress_teacherData")}
                    {!teacherList ? (
                      <CircularProgress />
                    ) : (
                      t("CourseImport.CircularProgress_teacherData_complete")
                    )}
                  </div>
                  {courseList && teacherList && (
                    <div>
                      {t("CourseImport.CircularProgress_checkData")}
                      {!checkResult.isDone ? (
                        <CircularProgress />
                      ) : (
                        t("CourseImport.CircularProgress_checkData_complete")
                      )}
                    </div>
                  )}
                  {checkResult.isDone && checkResult.errorAmount > 0 && (
                    <>
                      <div>
                        {t('CourseImport.CircularProgress_failCourse', { count: importDatas?.filter((p) => !p.isOK).length })}
                        {/* 發現{importDatas?.filter((p) => !p.isOK).length}筆錯誤： */}
                      </div>
                      <div className={styles.table_wrapper}>
                        <table className={styles.table} cellSpacing="0">
                          <thead>
                            <tr>
                              <th></th>
                              <th
                                className={classnames(
                                  styles.sticky_col,
                                  styles.sticky_col_one
                                )}
                              >
                                {" "}
                                {t("CourseImport.verification_table_className")}
                              </th>
                              <th>{t("CourseImport.verification_table_beginTime")}</th>
                              <th>{t("CourseImport.verification_table_expirationTime")}</th>
                              <th>{t("CourseImport.verification_table_teacherAccount")}</th>
                              <th>{t("CourseImport.verification_table_error")}</th>
                              <th></th>
                            </tr>
                          </thead>
                          <tbody>
                            {importDatas
                              ?.filter((p) => !p.isOK)
                              .map((item, index) => {
                                return (
                                  <tr key={index}>
                                    <td></td>
                                    <td
                                      data-label={`${t("CourseImport.verification_table_className")}:`}
                                      className={classnames(
                                        styles.sticky_col,
                                        styles.sticky_col_one
                                      )}
                                    >
                                      {item.courseName}
                                    </td>
                                    <td
                                      data-label={`${t("CourseImport.verification_table_beginTime")}:`}
                                    >
                                      <div>{item?.startTime} </div>
                                    </td>
                                    <td
                                      data-label={`${t("CourseImport.verification_table_expirationTime")}:`}
                                    >
                                      <div>{item?.expireTime}</div>
                                    </td>
                                    <td
                                      data-label={`${t("CourseImport.verification_table_teacherAccount")}:`}
                                    >
                                      <ul>
                                        {item.teachers.map((teacher, index) => {
                                          return (
                                            <li key={`teacher_${index}`}>
                                              {teacher}
                                            </li>
                                          );
                                        })}
                                      </ul>
                                    </td>
                                    <td>
                                      <ul className={styles.error}>
                                        {item.errors.map((error, index) => {
                                          return (
                                            <li key={`error_${index}`}>
                                              {" "}
                                              {error}
                                            </li>
                                          );
                                        })}
                                      </ul>
                                    </td>
                                    <td></td>
                                  </tr>
                                );
                              })}
                          </tbody>
                        </table>
                      </div>
                    </>
                  )}
                  {checkResult.isDone && checkResult.errorAmount === 0 && (
                    <>
                      {checkResult.sameAmount > 0 && (
                        <div>
                          {t("CourseImport.CheckResult_existingData", { var: checkResult.sameAmount })}
                          {override
                            ? t("CourseImport.CheckResult_cover")
                            : t("CourseImport.CheckResult_skip")}
                        </div>
                      )}
                      {t("CourseImport.CheckResult_dataPass")}
                    </>
                  )}
                </Grid>
              </>
            )}
            {activeStep === 2 && (
              <>
                <Box sx={{ width: "100%" }}>
                  <LinearProgress
                    variant="determinate"
                    value={uploadProgress?.porgress ?? 0}
                  />
                </Box>

                <div>
                  {uploadProgress?.isDone
                    ? t("CourseImport.UploadProgress_return")
                    : t("CourseImport.UploadProgress_uploading")}
                </div>
              </>
            )}
          </Grid>
        </Box>
      </Grid>
      <Grid item container justifyContent={"space-between"}>
        <Button color="inherit" onClick={handleBack} sx={{ mr: 1 }}>
          {activeStep === 0 || activeStep === steps.length - 1
            ? t("CourseImport.BackListBtn")
            : t("CourseImport.BackBtn")}
        </Button>
        <Button onClick={handleStep} disabled={!allowNextStep}>
          {activeStep === steps.length - 1
            ? t("CourseImport.ConfirmComplete")
            : t("CourseImport.NextBtn")}
        </Button>
      </Grid>
    </Grid>
  );
};

export default CourseImport;
