import React, { useEffect, useRef, useState } from "react";
import {
  Navigate,
  useLocation,
  Outlet,
  useParams,
  useNavigate,
} from "react-router-dom";
import styles from "./index.module.scss";
import * as d3 from "d3";
import { useTranslation } from "react-i18next";

const LineChart = (props) => {
  const { t, i18n } = useTranslation();
  const chatRef = useRef(null);
  const zoomInRef = useRef(null);
  const zoomOutRef = useRef(null);
  const { data, gap } = props;
  let svg;
  let zoom;

  const width = 1000;
  const height = 250;
  useEffect(() => {
    // 選擇第一個子元素刪除，避免重新渲染時重疊
    d3.select(chatRef.current).selectChild().remove();

    // 若 data 無資料則 return
    if (!data || data.length == 0) return;

    // 定義 svg 的範圍 [X軸, Y軸, 寬度, 高度]
    svg = d3.select(chatRef.current).attr("viewBox", [0, 0, width, height]);

    // 在 svg 建立 <g>
    let mainContainer = svg.append("g");

    // 座標位置
    const margin = { top: 20, right: 20, bottom: 30, left: 40 };

    // 時間比例尺
    const x = d3
      .scaleTime() // 建立時間比例尺
      .domain(d3.extent(data, (d) => d3.isoParse(d.time))) // 數據集中時間的範圍
      .nice() // 美化比例尺
      .range([margin.left, width - margin.right]); // 比例尺的範圍

    // Y軸的學習時間或人數，定義 Y 軸最大值
    let maxYNum = d3.max(data, (d) => d.value);
    // 少於 10 就為 10
    if (maxYNum < 10) maxYNum = 10;

    const y = d3
      .scaleLinear() // 線性比例尺
      .domain([0, maxYNum]) // 比例尺值的範圍
      .nice() // 美化比例尺
      .range([height - margin.bottom, margin.top]); // 比例尺螢幕的範圍

    let timeTick;
    if (gap < 24) timeTick = d3.timeHour.every(gap);
    else {
      const timeRange = x.domain();
      const timeDifference =
        (timeRange[1].getTime() - timeRange[0].getTime()) /
        (24 * 60 * 60 * 1000);
      timeTick = d3.timeDay.every(Math.ceil(timeDifference / 14));
    }

    // 建立 X軸
    const xAxis = (g) =>
      g
        .attr("transform", `translate(0,${height - margin.bottom})`)
        // axisBottom(x) 建立一個 X 軸
        // X 軸的刻度
        .call(d3.axisBottom(x).ticks(timeTick));

    // 建立 Y軸 (水平線)
    const yAxis = (g) =>
      g
        .attr("transform", `translate(${margin.left},0)`)
        .call(d3.axisLeft(y).ticks().tickFormat(d3.format("d")))
        .call((g) => g.select(".domain").remove())
        .call((g) =>
          g
            .selectAll(".tick line")
            .clone()
            .attr("x2", width)
            .attr("stroke-opacity", 0.1)
        );

    // 折線圖
    mainContainer
      .append("path")
      .datum(data)
      .attr("fill", "none")
      .attr("stroke", "#4463cb")
      .attr("stroke-width", 3)
      .attr(
        "d",
        d3
          .line()
          .x(function (d) {
            return x(d3.isoParse(d.time));
          })
          .y(function (d) {
            return y(d.value);
          })
      );

    mainContainer.append("g").attr("class", "x-axis").call(xAxis);

    mainContainer.append("g").attr("class", "y-axis").call(yAxis);

    mainContainer
      .append("g")
      .append("text")
      .text(t("LineChart.Text"))
      .attr("class", styles.chart_y_txt)
      .attr("x", 10)
      .attr("y", y(maxYNum));
  }, [data, i18n.language]);

  return (
    <div className={styles.chatArea}>
      {/* <ButtonGroup
                className={styles.chatarea_btngroup}
                orientation="vertical"
                size="small"
            >
                <Button
                    ref={zoomInRef}>
                    <Icon path={mdiPlus} size={1} />
                </Button>
                <Button
                    ref={zoomOutRef}>
                    <Icon path={mdiMinus} size={1} />
                </Button>
            </ButtonGroup> */}
      <svg ref={chatRef}></svg>
    </div>
  );
};

export default LineChart;
