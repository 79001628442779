import { createSlice } from '@reduxjs/toolkit';
const initialState = {
    authInitReady: false,
    isAuth: false,
    accessToken: null,
    refreshToken: null,
    enterpriseId: null,
    userAuthInfo: null,
    roles: null,
    managingEnterprises: null,
    permissionLevel: null
};

const checkUserAuth = (userAuth) => {
    if (!userAuth.accessToken)
        return false;

    if (userAuth.refearshToken < Date.now())
        return false;
    return true;
}
const authSlice = createSlice({
    name: 'auth',
    initialState: initialState,
    reducers: {
        setUserAuth(state, action) {
            const userAuth = action.payload;

            if (!userAuth)
                return initialState;
            if (!checkUserAuth(userAuth))
                return initialState;
            localStorage.setItem("userAuth", JSON.stringify(action.payload));

            return {
                ...state,
                isAuth: true,
                authInitReady: true,
                accessToken: userAuth.accessToken,
                refreshToken: userAuth.refreshToken,
                userAuthInfo: userAuth,
                enterpriseId: userAuth.enterpriseId,
                roles: userAuth.roles,
                managingEnterprises: userAuth.managingEnterprises
            };
        },
        reloadUserAuth(state, action) {
            let userAuthValue = localStorage.getItem('userAuth');
            if (!userAuthValue)
                return { ...initialState, authInitReady: true };

            const userAuth = JSON.parse(userAuthValue);

            if (!checkUserAuth(userAuth))
                return { ...initialState, authInitReady: true };

            return {
                ...state,
                authInitReady: true,
                isAuth: true,
                accessToken: userAuth.accessToken,
                refreshToken: userAuth.refreshToken,
                enterpriseId: userAuth.enterpriseId,
                userAuthInfo: userAuth,
                roles: userAuth.roles,
                managingEnterprises: userAuth.managingEnterprises
            };
        },
        setUserRoles(state, action) {
            const roles = action.payload;
            return { ...state, roles: roles };
        },
        setManagingEnterprises(state, action) {
            const managingEnterprises = action.payload;
            return { ...state, managingEnterprises: managingEnterprises };
        },
        clearUserAuth(state, action) {
            localStorage.removeItem('userAuth');
            return initialState;
        },
        setPermissionLevel(state, action) {
            return { ...state, permissionLevel: action.payload };
        }
    },
});

export const {
    setUserAuth,
    setUserRoles,
    setManagingEnterprises,
    clearUserAuth,
    reloadUserAuth,
    setPermissionLevel } = authSlice.actions;

export default authSlice.reducer;