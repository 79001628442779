import { fetchData } from "../api";

const login = async (enterpriseId, username, password) => {
    let loginData = {
        enterpriseId: enterpriseId,
        userName: username,
        password: password,
        platform: "Backend"
    };
    const path = `/Auth/Login`;

    return fetchData(path, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(loginData),
    });
};
export default { login };