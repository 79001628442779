import { useState, useEffect } from "react";
import styles from "./index.module.scss";
import classnames from "classnames";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import {
  Box,
  LinearProgress,
  MenuItem,
  Chip,
  FormControl,
  Grid,
  OutlinedInput,
  Tooltip,
  InputAdornment,
} from "@mui/material";
import recordsApi from "../../services/enterprise/records";
import DonutChart from "../DrawCharts/DonutChart";
// import "react-datepicker/dist/react-datepicker.css";
import Icon from "@mdi/react";
import {
  mdiTrendingUp,
  mdiTrendingDown,
  mdiAccountDetailsOutline,
  mdiNoteEditOutline,
  mdiClockOutline,
  mdiAvTimer,
  mdiHelpCircleOutline,
  mdiCalendarMonthOutline,
} from "@mdi/js";
import HistogramChart from "../HistogramChart";
import ActiveStatusHistogramChart from "../ActiveStatusHistogramChart";
import LineChart from "../LineChart";
import { useTimeUtils } from "../../hooks/timeUtils";
import RankCard from "../RankCard";
import { useQuery } from "@tanstack/react-query";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import zhTW from "date-fns/locale/zh-TW";
import { useTranslation } from "react-i18next";

const MyToolTip = ({ text }) => {
  return (
    <Tooltip
      arrow
      title={<span className={styles.tooltipText}>{text}</span>}
      placement="top-start"
      classes={{
        popper: styles.tooltip_popper,
        tooltip: styles.tooltip,
      }}
    >
      <Icon
        path={mdiHelpCircleOutline}
        size={1}
        className={styles.tooltip_icon}
      />
    </Tooltip>
  );
};

const LearnerAmountChart = ({
  dailyRecord,
  weekRecord,
  monthRecord,
  dateRangeRecord,
  upStartAndEnd,
}) => {
  const { t } = useTranslation();
  const [period, setPeriod] = useState("daily");
  const [data, setData] = useState([]);
  const [gap, setGap] = useState();

  //
  const [parentState, setParentState] = useState([null, null]);

  const updateParentState = (dateRange) => {
    setParentState(dateRange);
    upStartAndEnd(dateRange);
  };

  const setChartDate = (record) => {
    if (!record) {
      setData([]);
      setGap();
    } else {
      setData(
        record.data.map((p) => {
          return { time: p.time, value: p.usedUserAmount };
        })
      );
      setGap(record.gap);
    }
  };

  useEffect(() => {
    if (period != "daily" || !dailyRecord) return;
    setChartDate(dailyRecord);
  }, [period, dailyRecord]);
  useEffect(() => {
    if (period != "week" || !weekRecord) return;
    setChartDate(weekRecord);
  }, [period, weekRecord]);
  useEffect(() => {
    if (period != "month" || !monthRecord) return;
    setChartDate(monthRecord);
  }, [period, monthRecord]);
  useEffect(() => {
    if (period != "range" || !dateRangeRecord) return;
    setChartDate(dateRangeRecord);
  }, [period, dateRangeRecord]);

  return (
    <div className={styles.card}>
      <Grid container item xs={12} justifyContent={"space-between"}>
        <div>
          <div className={styles.card_title}>
            {t("RecordSummary.CardTitle_learnerAmountChart")}
          </div>
          <div className={styles.card_subtitle}></div>
        </div>
        <div className={styles.chart_btns} style={{ display: "flex" }}>
          <Chip
            className={styles.chart_btn}
            label={t("RecordSummary.LearnerAmountChart_button_daily")}
            clickable
            color={period == "daily" ? "primary" : "default"}
            variant="contained"
            onClick={() => setPeriod("daily")}
          />
          <Chip
            className={styles.chart_btn}
            label={t("RecordSummary.LearnerAmountChart_button_week")}
            clickable
            color={period == "week" ? "primary" : "default"}
            variant="contained"
            onClick={() => setPeriod("week")}
          />
          <Chip
            className={styles.chart_btn}
            label={t("RecordSummary.LearnerAmountChart_button_month")}
            clickable
            color={period == "month" ? "primary" : "default"}
            variant="contained"
            onClick={() => setPeriod("month")}
          />
          <DateRangeSelector
            onRangeSelected={() => setPeriod("range")}
            onStartAndEnd={updateParentState}
          />
        </div>
      </Grid>
      <Grid container item xs={12}>
        <HistogramChart data={data} gap={gap}></HistogramChart>
      </Grid>
    </div>
  );
};

const LearningTimeChart = ({
  dailyRecord,
  weekRecord,
  monthRecord,
  dateRangeRecord,
  upStartAndEnd,
}) => {
  const { t } = useTranslation();
  const [period, setPeriod] = useState("daily");
  const [data, setData] = useState([]);
  const [gap, setGap] = useState();

  const [parentState, setParentState] = useState([null, null]);

  const updateParentState = (dateRange) => {
    setParentState(dateRange);
    upStartAndEnd(dateRange);
  };

  const setChartDate = (record) => {
    if (!record) {
      setData([]);
      setGap();
    } else {
      setData(
        record.data.map((p) => {
          return { time: p.time, value: p.totalTraningSecond / 3600 };
        })
      );
      setGap(record.gap);
    }
  };

  useEffect(() => {
    if (period != "daily" || !dailyRecord) return;
    setChartDate(dailyRecord);
  }, [period, dailyRecord]);
  useEffect(() => {
    if (period != "week" || !weekRecord) return;
    setChartDate(weekRecord);
  }, [period, weekRecord]);
  useEffect(() => {
    if (period != "month" || !monthRecord) return;
    setChartDate(monthRecord);
  }, [period, monthRecord]);
  useEffect(() => {
    if (period != "range" || !dateRangeRecord) return;
    setChartDate(dateRangeRecord);
  }, [period, dateRangeRecord]);

  return (
    <div className={styles.card}>
      <Grid container item xs={12} justifyContent={"space-between"}>
        <div>
          <div className={styles.card_title}>
            {t("RecordSummary.CardTitle_learningTimeChart")}
          </div>
          <div className={styles.card_subtitle}></div>
        </div>
        <div className={styles.chart_btns} style={{ display: "flex" }}>
          <Chip
            className={styles.chart_btn}
            label={t("RecordSummary.LearningTimeChart_button_daily")}
            clickable
            color={period == "daily" ? "primary" : "default"}
            variant="contained"
            onClick={() => setPeriod("daily")}
          />
          <Chip
            className={styles.chart_btn}
            label={t("RecordSummary.LearningTimeChart_button_week")}
            clickable
            color={period == "week" ? "primary" : "default"}
            variant="contained"
            onClick={() => setPeriod("week")}
          />
          <Chip
            className={styles.chart_btn}
            label={t("RecordSummary.LearningTimeChart_button_month")}
            clickable
            color={period == "month" ? "primary" : "default"}
            variant="contained"
            onClick={() => setPeriod("month")}
          />
          <DateRangeSelector
            onRangeSelected={() => setPeriod("range")}
            onStartAndEnd={updateParentState}
          />
        </div>
      </Grid>
      <Grid container item xs={12}>
        <LineChart data={data} gap={gap}></LineChart>
      </Grid>
    </div>
  );
};

const ActiveStatusChart = ({ activeStatusRecord }) => {
  const { t, i18n } = useTranslation();
  const [data, setData] = useState([]);

  const setChartDate = (record) => {
    if (!record) {
      setData([]);
    } else {
      const dataRecords = record.data.map((p) => {
        return { time: p.hour, value: p.totalUserAmount };
      });
      // 每 4 組 為一筆 data
      const groupedRecords = dataRecords.reduce((acc, current, index) => {
        const groupIndex = Math.floor(index / 4);
        if (!acc[groupIndex]) {
          acc[groupIndex] = { time: 0, value: 0 };
        }

        // *4 : 使每筆 data 相差 4小時，+4 : 從 04 開始
        acc[groupIndex].time =
          groupIndex * 4 +
          4 +
          t("RecordSummary.ActiveStatusChart_chartDate_hour");
        let a = acc[groupIndex].time.toString().padStart(3, "0");
        acc[groupIndex].time = a;
        acc[groupIndex].value += current.value;
        return acc;
      }, []);

      setData(groupedRecords);
    }
  };

  useEffect(() => {
    if (!activeStatusRecord) return;
    setChartDate(activeStatusRecord);
  }, [activeStatusRecord, i18n.language]);

  return (
    <div className={styles.card}>
      <Grid container justifyContent="space-between">
        <div className={styles.card_title}>
          {t("RecordSummary.CardTitle_activeStatusChart")}
        </div>
      </Grid>
      <Grid container item xs={12}>
        <ActiveStatusHistogramChart data={data} />
      </Grid>
    </div>
  );
};

const CircleCard = ({
  title,
  data,
  descript,
  tooltip,
  dsiplayProcess,
  type,
}) => {
  return (
    <div className={styles.card}>
      <Grid container justifyContent="space-between">
        <div className={styles.card_title}>{title}</div>
        <div></div>
        {tooltip && <MyToolTip text={tooltip} />}
      </Grid>
      <Grid container direction="row">
        <Grid item xs={4}>
          <DonutChart data={data} />
        </Grid>
        <Grid
          item
          xs={8}
          container
          direction={"column"}
          paddingLeft={4}
          paddingRight={4}
          paddingTop={2}
          gap={0.5}
        >
          {data.map((item, index) => {
            return (
              <div key={index} className={styles.circle_item}>
                <span
                  className={styles.circle_item_point}
                  style={{ color: `${item.color}` }}
                >
                  ●
                </span>
                {item.name} : {item.display}
              </div>
            );
          })}
          <div className={styles.circle_descript}>{descript}</div>
        </Grid>
      </Grid>
      {/* <DonutChart data={TrainingChart} colors={["#00A3FF", "#4770FF"]} onMarkerMouseover={handlerMarkerMouseOver} onMarkerMouseout={handleTipPopoverClose} value={pieChartValue} />
                {!TrainingChart[0]?.value && <EstablishBtn />}
                <ChartMark data={TrainingArr} />
                <p className={styles.DonutChartDesc}>使用者作答與檢討的時間比例為 {` 1 : ${(TrainingChart[1]?.value / TrainingChart[0]?.value).toFixed(2)} `}, 在整體學習時間中, 較多的時間用於{TrainingChart[1]?.value > TrainingChart[0]?.value ? "檢討" : "作答"}, 另外作答時間約為檢討時間的{(1 / (TrainingChart[1]?.value / TrainingChart[0]?.value)).toFixed(2)}倍</p> */}
    </div>
  );
};

const DateRangeSelector = ({ onRangeSelected, onStartAndEnd }) => {
  const { t } = useTranslation();
  // 日期的範圍 [開始, 結束]
  const [dateRange, setDateRange] = useState([null, null]);
  const [startDate, endDate] = dateRange;

  // 警告提示
  const showInvalidRangeToast = () => {
    alert(t("RecordSummary.DateRangeSelector_errorAlert"));
  };

  // 開始與結束時間
  const handleRangeSelected = (dateRange) => {
    onStartAndEnd(dateRange);
  };

  // 發生改變時執行
  const onChange = (update) => {
    // 2592000000 milliseconds = 30 days
    const thirtyDay = 2592000000;
    const beginDay = update[0];
    const endDay = update[1];

    if (endDay - beginDay <= thirtyDay) {
      setDateRange(update);
    } else {
      // 顯示警告提示
      showInvalidRangeToast();
    }
    // 若開始時間與結束時間都有值，且兩者差距不超過 30 天。
    if (endDay && beginDay && endDay - beginDay <= thirtyDay) {
      // 更新開始與結束時間
      handleRangeSelected([beginDay, endDay]);
      // 執行 setPeriod("range")
      onRangeSelected();
    }
  };

  return (
    <div className="date-range-selector-container">
      {/*
       showIcon : icon 圖示A
       selectsRange : 是否可以選擇兩個日期
       startDate : 開始時間
       endDate : 結束時間
       onChange : 當選擇的日期發生變化時
       placeholderText : 提示欄位
       dateFormat : 選擇日期後所顯示的值，預設為 yyyy/MM/dd
       locale : 語言 (設定為中文)
       isClearable : 清除按鈕
       */}
      <DatePicker
        className={styles.data_range_selector}
        selectsRange={true}
        startDate={startDate}
        endDate={endDate}
        onChange={onChange}
        placeholderText={t(
          "RecordSummary.DateRangeSelector_placeholderText"
        )}
        dateFormat="MM/dd"
        locale={zhTW}
        isClearable={true}
        customInput={
          <OutlinedInput
            size="small"
            sx={{ minWidth: 50, maxWidth: 175 }}
            startAdornment={
              <InputAdornment position="start">
                <Icon path={mdiCalendarMonthOutline} size={0.8} />
              </InputAdornment>
            }
          />
        }
      />
    </div>
  );
};

const RecordSummary = () => {
  const { t, i18n } = useTranslation();
  const [activityProgress, setActivityProgress] = useState(0);
  const [masterChangeProgress, setMasterChangeProgress] = useState(0);
  const [activityData, setActivityData] = useState([]);
  const [traingTimeData, setTraingTimeData] = useState([]);
  const params = useParams();
  const { toHHMMSS } = useTimeUtils();
  const [amountChartRangeDay, setamountChartRangeDay] = useState([null, null]);
  const [timeChartRangeDay, setTimeChartRangeDay] = useState([null, null]);

  const updateLearnerAmountChartState = (dateRange) => {
    setamountChartRangeDay(dateRange);
  };
  const updateLearningTimeChartState = (dateRange) => {
    setTimeChartRangeDay(dateRange);
  };

  const amountChartBeginDay = amountChartRangeDay[0];
  const amountChartEndDay = amountChartRangeDay[1];

  const timeChartBeginDay = timeChartRangeDay[0];
  const timeChartEndDay = timeChartRangeDay[1];

  const fetchSummary = async () => {
    return await recordsApi.getSummary(params.enterpriseId).then((res) => {
      return res.data;
    });
  };

  const fetchDailyRecord = async () => {
    // 取得現在時間
    let now = new Date();
    // 當天時間
    let currentHourTime = new Date(
      now.getFullYear(),
      now.getMonth(),
      now.getDate(),
      now.getHours()
    ).getTime();

    let nextHourTime = currentHourTime + 1 * 60 * 60 * 1000;

    let today = new Date(nextHourTime);

    let yesterday = new Date(nextHourTime - 24 * 60 * 60 * 1000);

    let gap = 2;

    return await recordsApi
      .getDailySummary(params.enterpriseId, yesterday, today, gap)
      .then((res) => {
        return {
          data: res.data,
          gap: gap,
        };
      });
  };

  const fetchWeekRecord = async () => {
    let now = new Date();
    let todayTime = new Date(
      now.getFullYear(),
      now.getMonth(),
      now.getDate(),
      23,
      59,
      59
    ).getTime();
    let today = new Date(todayTime);
    let lateWeekDay = new Date(todayTime - 7 * 24 * 60 * 60 * 1000);
    lateWeekDay.setDate(lateWeekDay.getDate() - 7);
    let gap = 24;
    return await recordsApi
      .getDailySummary(params.enterpriseId, lateWeekDay, today, gap)
      .then((res) => {
        return {
          data: res.data,
          gap: gap,
        };
      });
  };

  const fetchMonthRecord = async () => {
    let now = new Date();
    let todayTime = new Date(
      now.getFullYear(),
      now.getMonth(),
      now.getDate(),
      23,
      59,
      59
    ).getTime();
    let today = new Date(todayTime);
    let lateMonthDay = new Date(todayTime);
    lateMonthDay.setMonth(lateMonthDay.getMonth() - 1);
    let gap = 24;

    return await recordsApi
      .getDailySummary(params.enterpriseId, lateMonthDay, today, gap)
      .then((res) => {
        return {
          data: res.data,
          gap: gap,
        };
      });
  };

  const fetchDateRangeRecord = async (begin, endDay) => {
    let endDay1 = new Date(
      endDay.getFullYear(),
      endDay.getMonth(),
      endDay.getDate(),
      23,
      59,
      59
    ).getTime();

    let end = new Date(endDay1);

    let gap = 24;

    return await recordsApi
      .getDailySummary(params.enterpriseId, begin, end, gap)
      .then((res) => {
        return {
          data: res.data,
          gap: gap,
        };
      });
  };

  const fetchActiveStatus = async () => {
    return await recordsApi
      .getHourlyUsageAnalytics(params.enterpriseId)
      .then((res) => {
        return {
          data: res.data,
        };
      });
  };

  const dailyRecord = useQuery({
    queryKey: ["fetchDailyRecord"],
    queryFn: fetchDailyRecord,
  });

  const weekRecord = useQuery({
    queryKey: ["fetchWeekRecord"],
    queryFn: fetchWeekRecord,
  });

  const monthRecord = useQuery({
    queryKey: ["fetchMonthRecord"],
    queryFn: fetchMonthRecord,
  });

  const activeStatusRecord = useQuery({
    queryKey: ["fetchActiveStatus"],
    queryFn: fetchActiveStatus,
  });

  const learnerAmountCharRecord = useQuery({
    queryKey: ["fetchDateRangeRecord", amountChartRangeDay],
    queryFn: () => fetchDateRangeRecord(amountChartBeginDay, amountChartEndDay),
  });

  const learningTimeChartRecord = useQuery({
    queryKey: ["fetchDateRangeRecord", timeChartRangeDay],
    queryFn: () => fetchDateRangeRecord(timeChartBeginDay, timeChartEndDay),
  });

  const { data: summaryInfo } = useQuery({
    queryKey: ["fetchSummary"],
    queryFn: fetchSummary,
  });

  useEffect(() => {
    if (!summaryInfo) return;
    if (summaryInfo.enabledStudentAmount == 0) setActivityProgress(0);
    else {
      let porgress =
        (summaryInfo.activityUserAmount * 100) /
        summaryInfo.enabledStudentAmount;
      setActivityProgress(porgress);
    }

    setMasterChangeProgress(
      summaryInfo.masterAchieveRate - summaryInfo.lastWeekMasterAchieveRate
    );

    const reviewActivityAmonunt =
      summaryInfo.sumActivityAmount - summaryInfo.firstActivityAmount;
    const activityData = [
      {
        name: t("RecordSummary.TotalLearning_dataName_first"),
        value: summaryInfo.firstActivityAmount,
        display: t("RecordSummary.FirstActivityAmount",{ var : summaryInfo.firstActivityAmount}),
        color: "#ffc700",
      },
      {
        name: t("RecordSummary.TotalLearning_dataName_review"),
        value: reviewActivityAmonunt,
        display: t("RecordSummary.ReviewActivityAmonunt",{ var : reviewActivityAmonunt}),
        color: "#ff7f36",
      },
    ];
    const traingTimeData = [
      {
        name: t("RecordSummary.AnsweringTime_dataName_answeringTime"),
        value: summaryInfo.sumQuizSecond,
        display: toHHMMSS(summaryInfo.sumQuizSecond),
        color: "#00a3ff",
      },
      {
        name: t("RecordSummary.AnsweringTime_dataName_reviewTime"),
        value: summaryInfo.sumConfirmSecond,
        display: toHHMMSS(summaryInfo.sumConfirmSecond),
        color: "#4770ff",
      },
    ];
    setActivityData(activityData);
    setTraingTimeData(traingTimeData);
  }, [summaryInfo, i18n.language]);

  return (
    <>
      <Box>
        <Box>
          <div className={styles.title}>{t("RecordSummary.Title")}</div>
          <div className={styles.description}>
            {t("RecordSummary.Description")}
          </div>
        </Box>
        <Box className={styles.main_content}>
          <Grid container alignItems="start" direction="row" spacing={2}>
            <Grid item xs={12} md={7}>
              <div
                className={classnames(
                  styles.card,
                  styles.learner_progress_card
                )}
              >
                <div
                  className={styles.card_title}
                  style={{ marginBottom: "30px" }}
                >
                  {t("RecordSummary.CardTitle_accountStatus")}
                </div>
                <div className={styles.learner_progress_wrapper}>
                  <LinearProgress
                    variant="buffer"
                    value={activityProgress}
                    valueBuffer={100}
                    classes={{
                      root: styles.learner_progress_root,
                      bar1Buffer: styles.learner_progress_bar1,
                      bar2Buffer: styles.learner_progress_bar2,
                    }}
                  />
                </div>
                <div className={styles.learner_progress_info}>
                  <div>
                    <span className={styles.learner_progress_bar1_point}>
                      ●
                    </span>
                    {t("RecordSummary.AccountStatus_text_active")}
                    {summaryInfo?.activityUserAmount}
                  </div>
                  <div>
                    <span className={styles.learner_progress_bar2_point}>
                      ●
                    </span>
                    {t("RecordSummary.AccountStatus_text_enabled")}
                    {summaryInfo?.enabledStudentAmount}
                  </div>
                </div>
              </div>
            </Grid>
            <Grid item xs={12} md={5}>
              <ActiveStatusChart activeStatusRecord={activeStatusRecord.data} />
            </Grid>

            <Grid container item xs={12} md={6}>
              <CircleCard
                title={t("RecordSummary.CardTitle_totalLearning")}
                data={activityData}
                tooltip={t("RecordSummary.Tooltip_totalLearning")}
              />
            </Grid>
            <Grid container item xs={12} md={6}>
              <CircleCard
                title={t("RecordSummary.CardTitle_answeringTime")}
                data={traingTimeData}
                tooltip={t("RecordSummary.Tooltip_answeringTime")}
              />
            </Grid>

            <Grid container item xs={12} xl={6}>
              <LearnerAmountChart
                dailyRecord={dailyRecord.data}
                weekRecord={weekRecord.data}
                monthRecord={monthRecord.data}
                dateRangeRecord={learnerAmountCharRecord.data}
                upStartAndEnd={updateLearnerAmountChartState}
              />
            </Grid>
            <Grid container item xs={12} xl={6}>
              <LearningTimeChart
                dailyRecord={dailyRecord.data}
                weekRecord={weekRecord.data}
                monthRecord={monthRecord.data}
                dateRangeRecord={learningTimeChartRecord.data}
                upStartAndEnd={updateLearningTimeChartState}
              />
            </Grid>
            <Grid item xs={12}>
              <RankCard></RankCard>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </>
  );
};

export default RecordSummary;
