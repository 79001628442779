import { fetchData, fetchDataWithRetry } from "../api";

const getUsers = async (enterpriseId, searchText = null, filterRoles = []) => {
    const path = `/Enterprise/${enterpriseId}/Users`;
    const config = {
        query: {
            searchText,
            filterRoles: filterRoles.join()
        }
    };
    return fetchDataWithRetry(path, config);
}


const getUserInfo = async (enterpriseId, userId) => {
    return fetchDataWithRetry(`/Enterprise/${enterpriseId}/Users/${userId}`);
}

const createUser = async (enterpriseId, postData) => {
    const path = `/Enterprise/${enterpriseId}/Users`;
    const config = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(postData),
    };
    return fetchDataWithRetry(path, config);
}


const patchUser = async (enterpriseId, userId, patchData) => {
    const path = `/Enterprise/${enterpriseId}/Users/${userId}`;
    const config = {
        method: 'PATCH',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(patchData),
    };
    return fetchDataWithRetry(path, config);
}

const removeUsers = async (enterpriseId, userIds) => {
    let deleteData = {
        userIds: userIds
    };
    const path = `/Enterprise/${enterpriseId}/Users`;
    const config = {
        method: 'DELETE',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(deleteData),
    };
    return fetchDataWithRetry(path, config);
}

const sendMail = async (enterpriseId, userIds, templateName, subject, htmlContent) => {
    const postData = {
        userId: userIds,
        subject: subject,
        templateName: templateName,
        htmlContent: htmlContent
    };
    const path = `/Enterprise/${enterpriseId}/Users/SendEmail`;
    const config = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(postData),
    };
    return fetchDataWithRetry(path, config);

}

const checkAvailable = async (enterpriseId, username) => {

    const path = `/Enterprise/${enterpriseId}/Users/CheckAvailable`;
    const config = {
        query: {
            username: username
        }
    };
    return fetchDataWithRetry(path, config);
}

const changeStatus = async (enterpriseId, userId, status) => {
    const patchData = {
        status: status
    };
    const path = `/Enterprise/${enterpriseId}/Users/${userId}/Status`;
    const config = {
        method: 'PATCH',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(patchData),
    };
    return fetchDataWithRetry(path, config);
}


export default {
    getUsers,
    getUserInfo,
    createUser,
    patchUser,
    removeUsers,
    sendMail,
    checkAvailable,
    changeStatus
}
