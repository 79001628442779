import { fetchData, fetchDataWithRetry } from "../api";


const getUserInfo = async () => {
    const path = `/MyAccount/UserInfo`;

    return fetchDataWithRetry(path);
}

export default {
    getUserInfo
};