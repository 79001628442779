import {
  Drawer,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Box,
  Divider,
  MenuItem,
  FormControl,
  Select,
  SvgIcon,
} from "@mui/material";
import {
  useNavigate,
  useLocation,
  useParams,
  matchRoutes,
} from "react-router-dom";
import styles from "./index.module.scss";
import classnames from "classnames";
import Icon from "@mdi/react";
import { useAuth } from "../../hooks/auth";
import { mdiEarth } from '@mdi/js';
import { style } from "d3";
import { useEffect, useState } from "react";
import enterpriseApi from "../../services/enterprise/enterpriseInfo";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import LanguageSelector from "../LanguageSelector";




const Sidebar = ({ navs, onClose }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const { logout } = useAuth();
  const params = useParams();
  const [selectedItem, setSelectedItem] = useState();
  const [enterpriseInfo, setEnterpriseInfo] = useState();
  const authStore = useSelector((state) => state.auth);
  const fetchEnterpriseInfo = async () => {
    await enterpriseApi.getInfo(params.enterpriseId).then((res) => {
      setEnterpriseInfo(res.data);
    });
  };

  useEffect(() => {
    const item = navs
      .flatMap((nav) => nav.items)
      .sort((a, b) => {
        return b.to.length - a.to.length;
      })
      .find(
        (item) =>
          location.pathname.indexOf(`/${params.enterpriseId}/${item.to}`) === 0
      );
    if (item) {
      setSelectedItem(item.id);
    }
    fetchEnterpriseInfo();
  }, []);

  useEffect(() => {
    if (location.state?.sidebar) setSelectedItem(location.state.sidebar);
  }, [location.state]);

  const handerOnClick = (item) => {
    if (onClose) onClose();
    navigate(item.to, { state: { sidebar: item.id } });
  };

  return (
    <>
      <Box sx={{ overflow: "auto" }} className={styles.sidebar}>
        <h5 className={styles.enterpriseName}>{enterpriseInfo?.name}</h5>
        <List>
          {navs.map((item) => {
            if (!!item.level && item.level > authStore.permissionLevel)
              return <div key={`empty-${item.id}`} />;
            else
              return (
                <div key={`main-${item.id}`}>
                  <ListItemButton
                    className={styles.main_menu}
                    onClick={() => handerOnClick(item)}
                  >
                    <ListItemIcon
                      style={{ minWidth: "fit-content", paddingRight: "8px" }}
                    >
                      <Icon path={item.icon} size={1} color={"black"} />
                    </ListItemIcon>
                    <ListItemText
                      primary={item.text}
                      primaryTypographyProps={{ fontSize: "16px" }}
                    />
                  </ListItemButton>
                  {item.items?.map((item) => {
                    if (!!item.level && item.level > authStore.permissionLevel)
                      return <div key={`empty-${item.id}`} />;
                    else
                      return (
                        <ListItem key={`child-${item.id}`} disablePadding>
                          <ListItemButton
                            className={classnames(styles.btn_sub_menu, {
                              [styles.btn_sub_menu_selected]:
                                selectedItem === item.id,
                            })}
                            onClick={() => handerOnClick(item)}
                            selected={selectedItem === item.id}
                          >
                            <ListItemText
                              primaryTypographyProps={{ fontSize: "14px" }}
                            >
                              <span className={styles.txt_sub_menu}>
                                {" "}
                                {item.text}
                              </span>
                            </ListItemText>
                          </ListItemButton>
                        </ListItem>
                      );
                  })}
                </div>
              );
          })}
          <Box sx={{ display: { xs: "block", sm: "none" } }}>
            <Divider></Divider>
            <ListItemButton>
              <LanguageSelector styles={{
                '.MuiOutlinedInput-input': {
                    padding: '0',  
                    fontSize: '14px'
                },
                '.MuiOutlinedInput-notchedOutline': {
                    border: 'none', 
                },
              }}/>
            </ListItemButton>
          </Box>
          <Box sx={{ display: { xs: "block", sm: "none" } }}>
            <Divider></Divider>
            <ListItemButton onClick={() => logout()}>
              <ListItemText
                primary={t("Sidebar.LogoutBtn")}
                primaryTypographyProps={{ fontSize: "14px" }}
              />
            </ListItemButton>
          </Box>
        </List>
      </Box>
    </>
  );
};

export default Sidebar;
