import { setUserAuth } from '../reducers/auth';

const BASE_URL = process.env.REACT_APP_TAGEASY_API_URL;

let dispatchFunction;
let isRefreshing = false;

const initApi = (dispatch) => {
    dispatchFunction = dispatch;
};
const getUserAuth = () => {
    const userAuth = localStorage.getItem('userAuth');
    if (!userAuth)
        throw new Error('Failed to get token');

    return JSON.parse(userAuth);
}

const handleTokenExpiration = async () => {
    try {

        const userAuth = getUserAuth();
        const refreshToken = userAuth.refreshToken;
        const url = `${BASE_URL}/Auth/RefreshToken`;
        const refreshResponse = await fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ refreshToken }),
        });

        if (!refreshResponse.ok) {
            throw new Error('Token refresh failed');
        }

        const refreshedData = await refreshResponse.json();

        dispatchFunction(setUserAuth(refreshedData.data));

    } catch (error) {
        throw new Error('Failed to refresh token');
    }
};

const formattedQuery = (query) => {

    return Object.fromEntries(
        Object.entries(query)
            .filter(([key, value]) => !!value).map(([key, value]) => {

                if (value instanceof Date) {
                    return [key, value.toISOString()];
                }
                return [key, value];
            })
    );
}

const waitForRefreshComplete = () => {
    return new Promise((resolve) => {
        const interval = setInterval(() => {
            if (!isRefreshing) {
                clearInterval(interval);
                resolve();
            }
        }, 200);
    });
}

const fetchDataWithProgress = async (path, config, onProgressCallback) => {
    return fetchDataWithRetry(path, config, true, onProgressCallback);
};

const fetchData = async (path, config) => {
    const url = config.query
        ? `${BASE_URL}${path}?${new URLSearchParams(formattedQuery(config.query))}`
        : `${BASE_URL}${path}`;


    const response = await fetch(url, config);

    if (!response.ok) {
        return response.json().then((data) => { throw new Error(data.Message) });
    }

    return response.json();
};

const fetchDataWithRetry = async (path, config, isXhr = false, onProgressCallback = null) => {
    // try {
    const userAuth = getUserAuth();
    const accessToken = userAuth?.accessToken;
    const mergedConfig = {
        ...config,
        headers: {
            ...config?.headers,
            Authorization: accessToken ? `Bearer ${accessToken}` : null,
        },
    };
    let response;

    if (isXhr) {
        response = await fetchWithXhr(path, mergedConfig, onProgressCallback);
    } else {
        const url = config?.query
            ? `${BASE_URL}${path}?${new URLSearchParams(formattedQuery(config.query))}`
            : `${BASE_URL}${path} `;
        response = await fetch(url, mergedConfig);
    }

    if (!response.ok) {
        const errorData = await response.json();

        if (response.status === 401 && errorData.Message === 'Token Expired') {

            if (!isRefreshing) {
                isRefreshing = true;
                await handleTokenExpiration();
                return fetchDataWithRetry(path, config, isXhr, onProgressCallback);
            }
            else {
                await waitForRefreshComplete();
                return fetchDataWithRetry(path, config, isXhr, onProgressCallback);
            }
        }

        throw new Error(errorData.Message);
    }

    return response.json();
    // } catch (error) {
    //     console.error(error);
    //     throw new Error('Failed to fetch data');
    // }
};

// 新增使用 XMLHttpRequest 的 fetchWithXhr 函數
const fetchWithXhr = (path, config, onProgressCallback) => {
    return new Promise((resolve, reject) => {
        const xhr = new XMLHttpRequest();
        const url = config?.query
            ? `${BASE_URL}${path}?${new URLSearchParams(formattedQuery(config.query))}`
            : `${BASE_URL}${path} `;

        xhr.open((config.method || 'GET'), url, true);

        // Set headers
        const userAuth = getUserAuth();
        const accessToken = userAuth?.accessToken;
        if (accessToken) {
            xhr.setRequestHeader('Authorization', `Bearer ${accessToken}`);
        }

        // 監聽上傳進度
        if (onProgressCallback && typeof onProgressCallback === 'function') {
            xhr.upload.addEventListener('progress', (e) => {
                if (e.lengthComputable) {
                    onProgressCallback(e);
                }
            });
        }


        xhr.onreadystatechange = () => {
            if (xhr.readyState === XMLHttpRequest.DONE) {
                try {
                    const response = {
                        ok: xhr.status >= 200 && xhr.status < 300,
                        status: xhr.status,
                        statusText: xhr.statusText,
                        headers: xhr.getAllResponseHeaders(),
                        url: xhr.responseURL,
                        json: () => Promise.resolve(JSON.parse(xhr.responseText)),
                        text: () => Promise.resolve(xhr.responseText),
                    };

                    if (response.ok) {
                        resolve(response);
                    } else {
                        reject(response);
                    }
                } catch (parseError) {
                    reject(parseError);
                }
            }
        };

        xhr.send(config.body);
    });
};

export { initApi, fetchData, fetchDataWithRetry, fetchDataWithProgress };