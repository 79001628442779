import { configureStore } from '@reduxjs/toolkit'
import user from './reducers/user'
import auth from './reducers/auth'

const store = configureStore({
    reducer: {
        auth: auth,
        user: user
    }
})

export default store