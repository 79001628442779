import React, { useEffect, useRef, useState } from "react";
import {
  Navigate,
  useLocation,
  Outlet,
  useParams,
  useNavigate,
} from "react-router-dom";
import styles from "./index.module.scss";
import * as d3 from "d3";
import { useTranslation } from "react-i18next";

const HistogramChart = (props) => {
  const { t, i18n } = useTranslation();
  const chatRef = useRef(null);
  const zoomInRef = useRef(null);
  const zoomOutRef = useRef(null);
  const { data, gap } = props;
  let svg;
  let zoom;
  let mainContainer;

  const width = 1000;
  const height = 250;

  useEffect(() => {
    d3.select(chatRef.current).selectChild().remove();
    if (!data || data.length == 0) return;

    svg = d3.select(chatRef.current).attr("viewBox", [0, 0, width, height]);

    mainContainer = svg.append("g");

    const margin = { top: 20, right: 20, bottom: 30, left: 40 };

    const x = d3
      .scaleTime()
      .domain(d3.extent(data, (d) => d3.isoParse(d.time)))
      .nice()
      .range([margin.left, width - margin.right]);

    let maxYNum = d3.max(data, (d) => d.value);
    if (maxYNum < 10) maxYNum = 10;
    const y = d3
      .scaleLinear()
      .domain([0, maxYNum])
      .nice()
      .range([height - margin.bottom, margin.top]);

    let timeTick;
    if (gap < 24) timeTick = d3.timeHour.every(gap);
    else {
      const timeRange = x.domain();
      const timeDifference =
        (timeRange[1].getTime() - timeRange[0].getTime()) /
        (24 * 60 * 60 * 1000);
      timeTick = d3.timeDay.every(Math.ceil(timeDifference / 14));
    }
    const xAxis = (g) =>
      g
        .attr("transform", `translate(0,${height - margin.bottom})`)
        .call(d3.axisBottom(x).ticks(timeTick));

    const yAxis = (g) =>
      g
        .attr("transform", `translate(${margin.left},0)`)
        .call(d3.axisLeft(y).ticks().tickFormat(d3.format("d")))
        .call((g) => g.select(".domain").remove())
        .call((g) =>
          g
            .selectAll(".tick line")
            .clone()
            .attr("x2", width)
            .attr("stroke-opacity", 0.1)
        );

    mainContainer
      .append("g")
      .attr("class", styles.chart_bar)
      .attr("fill", "#4463cb")
      .selectAll("rect")
      .data(data)
      .join("rect")
      .attr("x", (d) => x(d3.isoParse(d.time)) - 6)
      .attr("y", (d) => y(d.value))
      .attr("height", (d) => y(0) - y(d.value))
      .attr("width", 12);

    mainContainer.append("g").attr("class", "x-axis").call(xAxis);

    mainContainer.append("g").attr("class", "y-axis").call(yAxis);

    mainContainer
      .append("g")
      .append("text")
      .text(t("HistogramChart.Text"))
      .attr("class", styles.chart_y_txt)
      .attr("x", 10)
      .attr("y", y(maxYNum));
  }, [data, i18n.language]);



  return (
    <div className={styles.chatArea}>
      {/* <ButtonGroup
                className={styles.chatarea_btngroup}
                orientation="vertical"
                size="small"
            >
                <Button
                    ref={zoomInRef}>
                    <Icon path={mdiPlus} size={1} />
                </Button>
                <Button
                    ref={zoomOutRef}>
                    <Icon path={mdiMinus} size={1} />
                </Button>
            </ButtonGroup> */}
      <svg ref={chatRef}></svg>
    </div>
  );
};

export default HistogramChart;
