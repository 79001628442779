import { useAuth } from "./hooks/auth";
import { Outlet, useLocation, useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import { PermissionLevel } from "./constants/PermissionLevel";
import { useNavigate, useSearchParams } from 'react-router-dom';
import { reloadUserAuth, setPermissionLevel } from './reducers/auth';
import {
    Box,
    Grid,
    FormControl,
    Select,
    MenuItem,
    Backdrop,
    InputLabel,
    CircularProgress
} from "@mui/material";

const ProtectedRoute = () => {

    const [loading, setLoading] = useState(true);
    const authStore = useSelector(state => state.auth);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const params = useParams();
    const verifyPermissionLevel = (enterpriseId) => {
        if (!authStore.roles)
            return PermissionLevel.Forbidden;

        if (authStore.enterpriseId === enterpriseId) {
            if (authStore.roles.includes("SuperAdmin"))
                return PermissionLevel.SuperAdmin;
            if (authStore.roles.includes("DealerAdmin"))
                return PermissionLevel.DealerAdmin;
            if (authStore.roles.includes("EnterpriseAdmin"))
                return PermissionLevel.EnterpriseAdmin;
            if (authStore.roles.includes("Teacher"))
                return PermissionLevel.Teacher;
        }
        else {
            if (authStore.roles.includes("SuperAdmin"))
                return PermissionLevel.SuperAdmin;
            if (authStore.roles.includes("DealerAdmin") &&
                authStore.managingEnterprises &&
                authStore.managingEnterprises?.includes(enterpriseId))
                return PermissionLevel.EnterpriseAdmin;
        }
        return PermissionLevel.Forbidden;
    }




    useEffect(() => {

        if (!authStore.authInitReady)
            return;
        if (!authStore.isAuth || !authStore.enterpriseId) {
            navigate("/login");
            return;
        }

        if (!params.enterpriseId) {
            const url = `${authStore.enterpriseId}`;
            navigate(url);
            return;
        }
        const permissionLevel = verifyPermissionLevel(params.enterpriseId);

        if (permissionLevel > PermissionLevel.Forbidden) {
            if (authStore.permissionLevel !== permissionLevel)
                dispatch(setPermissionLevel(permissionLevel));
            setLoading(false);

            if (location.pathname == "/" + authStore.enterpriseId) {

                if (authStore.permissionLevel >= PermissionLevel.EnterpriseAdmin)
                    return navigate(`/${authStore.enterpriseId}/enterpriseInfo`);
                if (authStore.permissionLevel === PermissionLevel.Teacher)
                    return navigate(`/${authStore.enterpriseId}/courses`);
            }
        }
        else {
            navigate("/");
            return;
        }

    }, [authStore, params.enterpriseId, location]);


    return (<>
        <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={loading}
        >
            <CircularProgress color="inherit" />
        </Backdrop>
        <Outlet />
    </>)

    return;
};

export default ProtectedRoute;
